import BasicUiHelper from './BasicUiHelper';
import { END_LOCK_BLOCK_STEP_TYPE } from '../../../constants'

class EndLockBlockUiHelper extends BasicUiHelper {
  regex() {
    return END_LOCK_BLOCK_STEP_TYPE;
  }
  name() {
    return 'End Lock Block';
  }
  canTryAgain() {
    return false;
  }
  disableConditions() {
    return true;
  }
}
export default EndLockBlockUiHelper;
