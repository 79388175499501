import _ from 'underscore'
import get from 'lodash/get'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Loading } from 'lib/acromyrmex'
import { useParams } from 'react-router';
import {
  Col,
  Row,
  Alert,
  Accordion,
  Panel,
  Modal,
  Button,
} from '../../utility/UiComponents'
import EnableIntegrationForm from './EnableIntegrationForm'
import EmptyView from '../shared/EmptyView'
import { createEnabledIntegration as createEnabledIntegrationAction } from '../../actions/enabledIntegrations'
import { loadAvailableIntegration as loadAvailableIntegrationAction } from '../../actions/integrations'
import IntegrationApiDetails from '../shared/IntegrationApiDetails'
import PageLayout from '../shared/Layout/PageLayout'

class ShowAvailableIntegration extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      modalValue: null,
    }

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this)
  }

  componentDidMount() {
    const { loadAvailableIntegration, availableIntegrationId } = this.props

    loadAvailableIntegration(availableIntegrationId)
  }

  onFormSubmit() {
    const { createEnabledIntegration, formValues } = this.props
    const { modalValue } = this.state

    createEnabledIntegration({
      integrationName: modalValue['x-integration-slug'],
      ...formValues,
    })
  }

  openModal(modalValue) {
    this.setState({ showModal: true, modalValue })
  }

  closeModal() {
    this.setState({ showModal: false, modalValue: null })
  }

  renderApis() {
    const { integration } = this.props
    let counter = 0;

    const rows = []
    _.each(integration.paths, (actions) => {
      _.each(actions, (actionDetail, actionType) => {
        if (actionType === 'parameters') {
          return
        }

        const header = `${actionDetail['x-action-name'] || actionDetail.summary} - ${
          actionDetail.description
        }`;

        rows.push(
          <Accordion.Item eventKey={counter}>
            <Accordion.Header>{header}</Accordion.Header>
            <Accordion.Body>
              <IntegrationApiDetails
                integrationApi={actionDetail}
                hideDescription
                showResponse
              />
            </Accordion.Body>
          </Accordion.Item>,
        )

        counter += 1;
      })
    })

    return <Accordion>{rows}</Accordion>
  }

  render() {
    const { integration, isLoading, enabledIntegrations, currentUser, routes } =
      this.props
    const { modalValue, showModal } = this.state
    let content = null

    if (isLoading) {
      content = (
        <Alert variant="info" className="pull-left">
          <Loading /> Loading integration...
        </Alert>
      )
    } else if (!integration) {
      content = (
        <EmptyView
          header="Integration not found."
          content=""
          button={{
            pathname: '/admin/integrations/available/',
            content: 'View All Available Integrations',
            style: 'info',
          }}
        />
      )
    } else {
      content = (
        <div>
          <h4>Integration Step Types</h4>
          {this.renderApis()}
          <Modal show={showModal} onHide={this.closeModal} size="large">
            <Modal.Header closeButton>
              <Modal.Title>Enable {get(modalValue, 'info.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EnableIntegrationForm
                onSubmit={this.onFormSubmit}
                integration={modalValue}
                enabledIntegrations={enabledIntegrations}
                initialValues={{
                  integrationId: get(modalValue, 'x-integration-id'),
                }}
              />
            </Modal.Body>
          </Modal>
        </div>
      )
    }

    return (
      <PageLayout
        routes={routes}
        currentUser={currentUser}
        header={get(integration, 'info.title')}
        description={get(integration, 'info.description')}
        headerButtons={
          <Button
            size="sm"
            variant="success"
            className="pull-right"
            onClick={() => {
              this.openModal(integration)
            }}
          >
            Enable {get(integration, 'info.title')}
          </Button>
        }
        content={content}
      />
    )
  }
}

// Define property types
ShowAvailableIntegration.propTypes = {
  availableIntegrationId: PropTypes.string.isRequired,
  createEnabledIntegration: PropTypes.func.isRequired,
  currentUser: PropTypes.shape().isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  integration: PropTypes.shape(),
  loadAvailableIntegration: PropTypes.func.isRequired,
  formValues: PropTypes.shape(),
  enabledIntegrations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isLoading: PropTypes.bool,
}

ShowAvailableIntegration.defaultProps = {
  integration: null,
  isLoading: false,
  formValues: {},
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.users.currentUser,
    integration: state.integrations.availableIntegration.value,
    isLoading: state.integrations.availableIntegration.isLoading,
    enabledIntegrations: state.enabledIntegrations.enabledIntegrations.rows,
    formValues: state.form.enableIntegrationForm
      ? state.form.enableIntegrationForm.values
      : {},
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadAvailableIntegration: loadAvailableIntegrationAction,
      createEnabledIntegration: createEnabledIntegrationAction,
    },
    dispatch,
  )

const ShowAvailableIntegrationWithState = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShowAvailableIntegration)

const ShowAvailableIntegrationWrapper = (props) => {
  const { availableIntegrationId } = useParams()

  return (
    <ShowAvailableIntegrationWithState
      availableIntegrationId={availableIntegrationId}
      {...props}
    />
  )
}

export default ShowAvailableIntegrationWrapper
