import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { change, Field, FieldArray } from 'redux-form';
import { SelectInput, TemplateInput, Icon, DateInput } from 'lib/acromyrmex';
import VaultSelectInput from '../../../../shared/form/VaultSelectInput';
import { loadStructureList as loadStructureListAction } from '../../../../../actions/vault';
import { Row, Col, Button, Modal } from '../../../../../utility/UiComponents';
import { getAvailableProcessInputOptions } from '../../UiStepHelper';
import AddButton from '../../../../shared/form/AddButton';
import UiAttributeHelper from '../../../../vaultStructures/attributes/UiAttributeHelper';

class FillModal extends Component {
  static renderFills({ fields, fillableData, outputOptions, change }) {
    const fills = fields.map((member, index) =>
      FillModal.renderFill(
        member,
        fillableData,
        outputOptions,
        change,
        fields,
        index,
      ),
    );

    const noFillsLeft = fields.length >= fillableData.length;

    return (
      <div>
        {fills}
        <Row>
          <Col xs={12}>
            <AddButton
              onClick={() =>
                fields.push({ type: 'dynamic', fill: fillableData[0].id })
              }
              label="Add Fill"
              disabled={noFillsLeft}
            />
            {noFillsLeft && (
              <span
                className="text-info pull-right"
                style={{ marginTop: '5px', marginRight: '5px' }}
              >
                (All data is filled)
              </span>
            )}
          </Col>
        </Row>
      </div>
    );
  }

  static renderFill(
    fillName,
    fillableData,
    outputOptions,
    change,
    fields,
    index,
  ) {
    const fill = fields.get(index);
    let fillDataSelected = fillableData.find((f) => f.id === fill.fill);

    if (!fillDataSelected) {
      [fillDataSelected] = fillableData;
    }

    // capture the list of previously used fills so we don't duplicate
    const usedFills = [];
    fields.forEach((n, i, l) => {
      const obj = l.get(i);
      if (i < index) {
        usedFills.push(obj.id);
      }
    });

    return (
      <Row key={fillName} className="align-items-start mb-3">
        <Col xs={5} className="pr-2">
          <Field
            name={`${fillName}.fill`}
            component={SelectInput}
            noLabel
            onChange={() => {
              change(`${fillName}.static`, '');
              change(`${fillName}.dynamic`, '');
              change(`${fillName}.type`, 'dynamic');
            }}
            options={fillableData
              .map((d) => ({ name: `${d.name} (${d.type})`, id: d.id }))
              .filter((d) => !usedFills.includes(d.id))}
          />
        </Col>
        <Col xs={5} className="px-2">
          {['dateTime', 'date', 'time'].includes(fillDataSelected.type) && (
            <Field
              name={`${fillName}.dynamic`}
              component={DateInput}
              noLabel
              options={outputOptions.filter(
                (opt) => opt.type === fillDataSelected.type,
              )}
              showTimeSelect={fillDataSelected.type !== 'date'}
              showDateSelect={fillDataSelected.type !== 'time'}
              getCalendarContainer={(trigger) => trigger.parentNode}
            />
          )}
          {fillDataSelected.type === 'boolean' && (
            <Field
              name={`${fillName}.dynamic`}
              component={SelectInput}
              noLabel
              options={[
                { id: 'true', name: 'true' },
                { id: 'false', name: 'false' },
              ]}
              templateOptions={outputOptions.filter(
                (opt) => opt.type === fillDataSelected.type,
              )}
            />
          )}
          {!['dateTime', 'date', 'time', 'boolean'].includes(
            fillDataSelected.type,
          ) && (
            <Field
              name={`${fillName}.dynamic`}
              component={TemplateInput}
              options={outputOptions.filter(
                (opt) =>
                  opt.type === fillDataSelected.type ||
                  ((fillDataSelected.type === 'string' ||
                    fillDataSelected.type === 'text') &&
                    (opt.type === 'number' || opt.type === 'integer')) ||
                  UiAttributeHelper.extendedTypeMatches(
                    fillDataSelected.type,
                    opt,
                  ),
              )}
              disabled={fill.type !== 'dynamic'}
              noLabel
              onTemplateClicked={(option) => {
                change(`${fillName}.dynamic`, option.textValue);
              }}
            />
          )}
        </Col>
        <Col xs={2} className="text-right pl-2">
          <Button variant="danger" onClick={() => fields.remove(index)}>
            <Icon remove />
          </Button>
        </Col>
      </Row>
    );
  }

  // determine the field to render for static value
  static getStaticField(fillDataSelected, fillName, fill) {
    let field = null;
    if (fillDataSelected && fillDataSelected.type.startsWith('vault')) {
      // the slug begins after 'vault-'
      const slug = fillDataSelected.type.substr(6);
      field = (
        <VaultSelectInput
          slug={slug}
          columns={12}
          noLabel
          name={`${fillName}.static`}
          disabled={fill.type !== 'static'}
          normalize={(value) => (value === 'none' ? null : value)}
        />
      );
    } else {
      field = UiAttributeHelper.getEditField(
        { ...fillDataSelected, key: `${fillName}.static` },
        '',
        null,
        {
          noLabel: true,
          disabled: fill.type !== 'static',
        },
      );
    }

    return field;
  }

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentWillMount() {
    const { loadStructureList } = this.props;

    loadStructureList();
  }

  componentDidUpdate(prevProps) {
    const { fillProcess, change, fieldText } = this.props;
    const preSelectedProcess = prevProps.fillProcess._id;
    const selectedProcess = fillProcess._id;

    if (preSelectedProcess !== selectedProcess) {
      change(`${fieldText}.stepOptions.fills`, []);
    }
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const {
      change,
      integrations,
      structureList,
      processes,
      fieldText,
      fillProcess,
      outputOptions,
      nameOverride,
    } = this.props;
    const { showModal } = this.state;

    if (!processes) {
      return (
        <div>
          <Button variant="primary" className="form-pad-label" disabled>
            Fill Data
          </Button>
        </div>
      );
    }

    const selectedProcess = fillProcess;

    const fillableData = selectedProcess
      ? getAvailableProcessInputOptions(
          selectedProcess,
          integrations,
          structureList,
        )
      : [];

    return (
      <div>
        <Button
          variant="primary"
          className="form-pad-label"
          onClick={this.openModal}
        >
          Fill Data
        </Button>
        <Modal
          show={showModal}
          onHide={this.closeModal}
          size="large"
          className="process-pre-fill"
        >
          <Modal.Header closeButton>
            <Modal.Title>Fill Data for process</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Enter any data that you would like to propagate to the sub-process
            </p>
            <Row>
              <Col xs={5} style={{ marginBottom: '10px' }}>
                <strong>Fill</strong>
              </Col>
              <Col xs={5} style={{ marginBottom: '10px' }}>
                <strong>Value</strong>
              </Col>
            </Row>
            <FieldArray
              name={nameOverride || `${fieldText}.stepOptions.fills`}
              component={FillModal.renderFills}
              props={{
                fillableData,
                outputOptions,
                change,
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={this.closeModal}>
              Done
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

FillModal.propTypes = {
  change: PropTypes.func.isRequired,
  nameOverride: PropTypes.string,
  fillProcess: PropTypes.shape().isRequired,
  integrations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  processStep: PropTypes.shape(),
  processes: PropTypes.arrayOf(PropTypes.shape()),
  fieldText: PropTypes.string,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()),

  // from redux
  loadStructureList: PropTypes.func.isRequired,
  structureList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

FillModal.defaultProps = {
  processes: [],
  nameOverride: null,
  processStep: null,
  fieldText: null,
  outputOptions: [],
};

const mapStateToProps = (state) => ({
  structureList: state.vault.structureList.rows,
  integrations: state.enabledIntegrations.enabledIntegrations.rows,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadStructureList: loadStructureListAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(FillModal);
