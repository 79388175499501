"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _superagent = _interopRequireDefault(require("superagent"));
class ProcessServer {
  static findBackgroundTasks(serverUri, _ref) {
    var {
      token,
      query = {}
    } = _ref;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.findBackgroundTasks requires a token');
    }
    return request.get("".concat(serverUri, "/backgroundTasks/")).query(query).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error getting background tasks, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static getBackgroundTaskById(serverUri, _ref2) {
    var {
      token,
      taskId
    } = _ref2;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.getBackgroundTaskById requires a token');
    }
    if (!taskId) {
      throw new Error('ProcessServer.getBackgroundTaskById requires a taskId');
    }
    return request.get("".concat(serverUri, "/backgroundTasks/").concat(taskId)).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error getting the background task, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static deleteBackgroundTask(serverUri, _ref3) {
    var {
      token,
      taskId
    } = _ref3;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.deleteBackgroundTask requires a token');
    }
    if (!taskId) {
      throw new Error('ProcessServer.deleteBackgroundTask requires a taskId');
    }
    return request.delete("".concat(serverUri, "/backgroundTasks/").concat(taskId)).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error deleting the background task, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static reRunBackgroundTask(serverUri, _ref4) {
    var {
      token,
      taskId
    } = _ref4;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.reRunBackgroundTask requires a token');
    }
    if (!taskId) {
      throw new Error('ProcessServer.reRunBackgroundTask requires a taskId');
    }
    return request.post("".concat(serverUri, "/backgroundTasks/").concat(taskId, "/rerun")).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error re-running the background task, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static sendHangingSteps(serverUri, _ref5) {
    var {
      token
    } = _ref5;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    return request.post("".concat(serverUri, "/executions/reports/hangingSteps")).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error sending hanging steps report, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static updateExecution(serverUri, _ref6) {
    var {
      token,
      execution
    } = _ref6;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!execution) {
      throw new Error('ProcessServer.updateExecution requires an execution');
    }
    if (!token) {
      throw new Error('ProcessServer.updateExecution requires a token');
    }
    return request.put("".concat(serverUri, "/executions/").concat(execution._id)).send(execution).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error updating execution, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static pushStepOutputArray(serverUri, _ref7) {
    var {
      token,
      executionId,
      stepId,
      key,
      value
    } = _ref7;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.pushStepOutputArray requires an token');
    }
    if (!executionId) {
      throw new Error('ProcessServer.pushStepOutputArray requires an executionId');
    }
    if (!stepId) {
      throw new Error('ProcessServer.pushStepOutputArray requires a stepId');
    }
    if (!key) {
      throw new Error('ProcessServer.pushStepOutputArray requires a key');
    }
    if (!value) {
      throw new Error('ProcessServer.pushStepOutputArray requires a value');
    }
    return request.post("".concat(serverUri, "/executions/").concat(executionId, "/pushStepOutputArray")).send({
      key,
      value,
      stepId
    }).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error updating execution, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static sendUpdates(serverUri, _ref8) {
    var {
      token,
      execution
    } = _ref8;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.sendUpdates requires an token');
    }
    if (!execution) {
      throw new Error('ProcessServer.sendUpdates requires an execution');
    }
    return request.post("".concat(serverUri, "/executions/sendUpdates")).send(execution).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error completing step, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static completeStep(serverUri, _ref9) {
    var {
      token,
      executionStepId,
      stepOutput,
      error,
      errorStatus,
      contuitId,
      collaborationCompleted,
      timedOut
    } = _ref9;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.completeStep requires an token');
    }
    if (!executionStepId) {
      throw new Error('ProcessServer.completeStep requires an executionStepId');
    }
    return request.post("".concat(serverUri, "/executions/completeStep/").concat(executionStepId)).send({
      stepOutput,
      error,
      errorStatus,
      collaborationCompleted,
      timedOut
    }).query({
      contuitId
    }).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error completing step, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static completeProcessSteps(serverUri, _ref10) {
    var {
      token,
      executionId,
      processStepIds,
      skippedByBlock,
      skippedByTerminateExecutionStep
    } = _ref10;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.completeProcessSteps requires an token');
    }
    if (!executionId) {
      throw new Error('ProcessServer.completeProcessSteps requires an executionStepId');
    }
    return request.post("".concat(serverUri, "/executions/completeProcessSteps/").concat(executionId)).send({
      processStepIds,
      skippedByBlock,
      skippedByTerminateExecutionStep
    }).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error completing step, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static updateStep(serverUri, _ref11) {
    var {
      token,
      executionStepId,
      stepOutput,
      error,
      errorStatus,
      contuitId,
      collaborationMessageId,
      collaborationCompleted
    } = _ref11;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.updateStep requires an token');
    }
    if (!executionStepId) {
      throw new Error('ProcessServer.updateStep requires an executionStepId');
    }
    return request.post("".concat(serverUri, "/executions/updateStep/").concat(executionStepId)).send({
      stepOutput,
      error,
      errorStatus,
      collaborationMessageId,
      collaborationCompleted
    }).query({
      contuitId
    }).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error updating step, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static creatSubExecution(serverUri, _ref12) {
    var {
      token,
      parent,
      assignee,
      process,
      prefill,
      parentStepId,
      contuitId
    } = _ref12;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!parent) {
      throw new Error('ProcessServer.creatSubExecution requires an execution');
    }
    if (!token) {
      throw new Error('ProcessServer.creatSubExecution requires a token');
    }
    if (!assignee) {
      throw new Error('ProcessServer.creatSubExecution requires a assignee');
    }
    if (!process) {
      throw new Error('ProcessServer.creatSubExecution requires a process');
    }
    return request.post("".concat(serverUri, "/executions/child")).send({
      parent,
      parentStepId,
      assignee,
      prefill
    }).query({
      process,
      contuitId
    }).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response
      } = err;
      var msg = status === 500 ? 'Unknown error creating execution, please try again later.' : response && response.body && response.body.message;
      throw new Error(msg);
    });
  }
  static triggerProcess(serverUri, _ref13) {
    var {
      token,
      processId,
      triggerData,
      preFill,
      userId
    } = _ref13;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.triggerProcess requires a token');
    }
    if (!processId) {
      throw new Error('ProcessServer.triggerProcess requires a processId');
    }
    return request.post("".concat(serverUri, "/executions")).send({
      triggerData,
      preFill,
      userId
    }).query({
      process: processId
    }).set('x-access-token', token).then(res => res ? res.body : []).catch(err => {
      var {
        response
      } = err;
      var msg = response && response.body.message ? response && response.body.message : 'Unknown error triggering processes, please try again later.';
      throw new Error(msg);
    });
  }
  static tryStepAgain(serverUri, _ref14) {
    var {
      token,
      execution
    } = _ref14;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!execution) {
      throw new Error('ProcessServer.tryStepAgain requires an execution');
    }
    if (!token) {
      throw new Error('ProcessServer.tryStepAgain requires a token');
    }
    return request.post("".concat(serverUri, "/executions/").concat(execution._id, "/tryAgain")).send(execution).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        body
      } = err.response;
      var msg = !body.message ? 'Unknown error updating execution, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static deleteExecution(serverUri, _ref15) {
    var {
      token,
      executionId
    } = _ref15;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!executionId) {
      throw new Error('ProcessServer.deleteExecution requires an executionId');
    }
    if (!token) {
      throw new Error('ProcessServer.deleteExecution requires a token');
    }
    return request.delete("".concat(serverUri, "/executions/").concat(executionId)).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error deleting execution, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static findProcess(serverUri, _ref16) {
    var {
      token,
      processId,
      query = {}
    } = _ref16;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.findProcess requires a token');
    }
    if (!processId) {
      throw new Error('ProcessServer.findProcess requires a processId');
    }
    return request.get("".concat(serverUri, "/").concat(processId)).query(query).set('x-access-token', token).then(res => res ? res.body : []).catch(err => {
      var {
        response
      } = err;
      var msg = response && response.body.message ? response && response.body.message : 'Unknown error getting processes, please try again later.';
      throw new Error(msg);
    });
  }
  static findProcesses(serverUri, _ref17) {
    var {
      token,
      query = {}
    } = _ref17;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.findProcesses requires a token');
    }
    return request.get("".concat(serverUri, "/")).query(query).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error getting processes, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static publishProcess(serverUri, _ref18) {
    var {
      token,
      processId
    } = _ref18;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.publishProcess requires a token');
    }
    if (!processId) {
      throw new Error('ProcessServer.publishProcess requires a processId');
    }
    return request.post("".concat(serverUri, "/").concat(processId, "/publish")).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        response: {
          body
        }
      } = err;
      var msg = !body.message ? 'Unknown error getting processes, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static validateProcess(serverUri, _ref19) {
    var {
      token,
      processId
    } = _ref19;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.validateProcess requires a token');
    }
    if (!processId) {
      throw new Error('ProcessServer.validateProcess requires a processId');
    }
    return request.get("".concat(serverUri, "/").concat(processId, "/validate")).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        response: {
          body
        }
      } = err;
      if (body.validationErrors) {
        var error = new Error('Invalid Process');
        error.validationErrors = body.validationErrors;
        throw error;
      }
      var msg = !body.message ? 'Unknown error getting processes, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static deleteCallbackAndHook(serverUri, _ref20) {
    var {
      token,
      processId
    } = _ref20;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.deleteCallbackAndHook requires a token');
    }
    if (!processId) {
      throw new Error('ProcessServer.deleteCallbackAndHook requires a processId');
    }
    return request.delete("".concat(serverUri, "/").concat(processId, "/triggerInitiation")).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        response: {
          body
        }
      } = err;
      var msg = !body.message ? 'Unknown error getting processes, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static createCallbackAndHook(serverUri, _ref21) {
    var {
      token,
      processId
    } = _ref21;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.createCallbackAndHook requires a token');
    }
    if (!processId) {
      throw new Error('ProcessServer.createCallbackAndHook requires a processId');
    }
    return request.post("".concat(serverUri, "/").concat(processId, "/triggerInitiation")).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        response: {
          body
        }
      } = err;
      var msg = !body.message ? 'Unknown error getting processes, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static getMyImportedProcesses(serverUri, _ref22) {
    var {
      token
    } = _ref22;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.getMyImportedProcesses requires a token');
    }
    return request.get("".concat(serverUri, "/")).query({
      communityId: {
        $ne: null
      }
    }).set('x-access-token', token).then(res => {
      var communityVersions = [];
      res.body.forEach(importedProcess => {
        if (!importedProcess.communityVersion || communityVersions.indexOf(importedProcess.communityVersion) > -1) {
          return;
        }
        communityVersions.push(importedProcess.communityVersion);
      });
      return communityVersions;
    }).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error getting processes, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static getAverageStepDurations(serverUri, _ref23) {
    var {
      id
    } = _ref23;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!id) {
      throw new Error('ProcessServer.getAverageStepDurations requires a id');
    }
    return request.get("".concat(serverUri, "/").concat(id, "/durations/")).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error getting process, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static updateProcess(serverUri, _ref24) {
    var {
      token,
      process
    } = _ref24;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!process) {
      throw new Error('ProcessServer.updateProcess requires an process');
    }
    if (!token) {
      throw new Error('ProcessServer.updateProcess requires a token');
    }
    return request.put("".concat(serverUri, "/").concat(process._id)).send(process).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      if (body.validationErrors) {
        var error = new Error('Invalid Process');
        error.validationErrors = body.validationErrors;
        throw error;
      }
      var msg = status === 500 ? 'Unknown error updating process, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static importProcess(serverUri, _ref25) {
    var {
      token,
      processMapping
    } = _ref25;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    return request.post("".concat(serverUri, "/import")).send(processMapping).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        response: {
          body
        }
      } = err;
      var msg = !body.message ? 'Unknown error updating process, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static deleteProcess(serverUri, _ref26) {
    var {
      token,
      processId
    } = _ref26;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!processId) {
      throw new Error('ProcessServer.deleteProcess requires an processId');
    }
    if (!token) {
      throw new Error('ProcessServer.deleteProcess requires a token');
    }
    return request.delete("".concat(serverUri, "/").concat(processId)).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error deleting execution, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static findExecutions(serverUri, _ref27) {
    var {
      token,
      query = {},
      shell = false
    } = _ref27;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.findExecutions requires a token');
    }
    return request.get("".concat(serverUri, "/executions/").concat(shell ? 'shell' : '')).query(query).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error getting executions, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static findStuckExecutions(serverUri, _ref28) {
    var {
      token,
      query = {}
    } = _ref28;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.findStuckExecutions requires a token');
    }
    return request.get("".concat(serverUri, "/executions/stuck")).query(query).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error getting executions, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static findExecution(serverUri, _ref29) {
    var {
      token,
      executionId = {}
    } = _ref29;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.findExecution requires a token');
    }
    if (!executionId) {
      throw new Error('ProcessServer.findExecution requires a executionId');
    }
    return request.get("".concat(serverUri, "/executions/").concat(executionId)).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error getting execution, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static deleteCompletedExecutions(serverUri, _ref30) {
    var {
      token
    } = _ref30;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.deleteCompletedExecutions requires a token');
    }
    return request.delete("".concat(serverUri, "/executions/completed")).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        response
      } = err;
      var msg = response && response.body.message ? response && response.body.message : 'Unknown error deleting completed executions, please try again later.';
      throw new Error(msg);
    });
  }
  static getSettings(serverUri, _ref31) {
    var {
      token,
      contuitId
    } = _ref31;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.getSettings requires a token');
    }
    return request.get("".concat(serverUri, "/settings")).set('x-access-token', token).query({
      contuitId
    }).then(res => res.body).catch(err => {
      var {
        response
      } = err;
      var msg = response && response.body.message ? response && response.body.message : 'Unknown error getting settings, please try again later.';
      throw new Error(msg);
    });
  }
  static updateSettings(serverUri, _ref32) {
    var {
      token,
      contuitId,
      data
    } = _ref32;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.updateSettings requires a token');
    }
    return request.put("".concat(serverUri, "/settings")).send({
      data
    }).set('x-access-token', token).query({
      contuitId
    }).then(res => res.body).catch(err => {
      var {
        response
      } = err;
      var msg = response && response.body.message ? response && response.body.message : 'Unknown error updating settings, please try again later.';
      throw new Error(msg);
    });
  }
  static getVaultStructureProcesses(serverUri, _ref33) {
    var {
      token,
      contuitId,
      slug
    } = _ref33;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!slug) {
      throw new Error('ProcessServer.getVaultStructureProcesses requires an slug');
    }
    if (!token) {
      throw new Error('ProcessServer.getVaultStructureProcesses requires a token');
    }
    return request.get("".concat(serverUri, "/vault/").concat(slug)).set('x-access-token', token).query({
      contuitId
    }).then(res => res.body).catch(err => {
      var {
        response
      } = err;
      var msg = response && response.body.message ? response && response.body.message : 'Unknown error getting vault structure processes, please try again later.';
      throw new Error(msg);
    });
  }
  static getSolutionGroups(serverUri, _ref34) {
    var {
      token
    } = _ref34;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.getSolutionGroups requires a token');
    }
    return request.get("".concat(serverUri, "/solutionGroups/")).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        response
      } = err;
      var msg = response && response.body.message ? response && response.body.message : 'Unknown error getting solution groups, please try again later.';
      throw new Error(msg);
    });
  }
  static getSolutionGroup(serverUri, _ref35) {
    var {
      token,
      solutionGroupId
    } = _ref35;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!solutionGroupId) {
      throw new Error('ProcessServer.getSolutionGroup requires a solutionGroupId');
    }
    if (!token) {
      throw new Error('ProcessServer.getSolutionGroup requires a token');
    }
    return request.get("".concat(serverUri, "/solutionGroups/").concat(solutionGroupId)).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        response
      } = err;
      var msg = response && response.body && response.body.message ? response.body.message : 'Unknown error getting solution group details, please try again later.';
      throw new Error(msg);
    });
  }
  static updateSolutionGroup(serverUri, _ref36) {
    var {
      token,
      solutionGroupId,
      data
    } = _ref36;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!solutionGroupId) {
      throw new Error('ProcessServer.updateSolutionGroup requires a solutionGroupId');
    }
    if (!token) {
      throw new Error('ProcessServer.updateSolutionGroup requires a token');
    }
    return request.put("".concat(serverUri, "/solutionGroups/").concat(solutionGroupId)).set('x-access-token', token).send(data).then(res => res.body).catch(err => {
      var {
        response
      } = err;
      var msg = response && response.body && response.body.message ? response.body.message : 'Unknown error updating solution group, please try again later.';
      throw new Error(msg);
    });
  }
  static deleteSolutionGroup(serverUri, _ref37) {
    var {
      token,
      solutionGroupId
    } = _ref37;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!solutionGroupId) {
      throw new Error('ProcessServer.deleteSolutionGroup requires a solutionGroupId');
    }
    if (!token) {
      throw new Error('ProcessServer.deleteSolutionGroup requires a token');
    }
    return request.delete("".concat(serverUri, "/solutionGroups/").concat(solutionGroupId)).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        response
      } = err;
      var msg = response && response.body && response.body.message ? response.body.message : 'Unknown error deleting solution group, please try again later.';
      throw new Error(msg);
    });
  }
  static getExecutionShells(serverUri, _ref38) {
    var {
      token,
      query = {}
    } = _ref38;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.getExecutionShells requires a token');
    }
    return request.get("".concat(serverUri, "/executions/shell")).query(query).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error getting execution shells, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static getVaultExecutions(serverUri, _ref39) {
    var {
      token,
      slug,
      id
    } = _ref39;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.getVaultExecutions requires a token');
    }
    if (!slug) {
      throw new Error('ProcessServer.getVaultExecutions requires a slug');
    }
    return request.get("".concat(serverUri, "/executions/vault")).query({
      slug,
      id
    }).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error getting vault executions, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static deleteExecutions(serverUri, _ref40) {
    var {
      token,
      selectionList
    } = _ref40;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!selectionList) {
      throw new Error('ProcessServer.deleteExecutions requires an selectionList');
    }
    if (!token) {
      throw new Error('ProcessServer.deleteExecutions requires a token');
    }
    return request.delete("".concat(serverUri, "/executions")).set('x-access-token', token).send({
      selectionList
    }).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error deleting execution, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static getBlockWaitings(serverUri, _ref41) {
    var {
      token
    } = _ref41;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('ProcessServer.getBlockWaitings requires a token');
    }
    return request.get("".concat(serverUri, "/blockWaitings")).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error fetching block waitings, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  constructor(serverUri) {
    var request = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _superagent.default;
    this._serverUri = serverUri;
    this._request = request;
  }
  creatSubExecution(options) {
    return ProcessServer.creatSubExecution(this._serverUri, options, this._request);
  }
  updateExecution(options) {
    return ProcessServer.updateExecution(this._serverUri, options, this._request);
  }
  pushStepOutputArray(options) {
    return ProcessServer.pushStepOutputArray(this._serverUri, options, this._request);
  }
  triggerProcess(options) {
    return ProcessServer.triggerProcess(this._serverUri, options, this._request);
  }
  findProcess(options) {
    return ProcessServer.findProcess(this._serverUri, options, this._request);
  }
  deleteExecution(_ref42) {
    var {
      token,
      executionId
    } = _ref42;
    return ProcessServer.deleteExecution(this._serverUri, {
      token,
      executionId
    });
  }
  completeStep(options) {
    return ProcessServer.completeStep(this._serverUri, options, this._request);
  }
  completeProcessSteps(options) {
    return ProcessServer.completeProcessSteps(this._serverUri, options, this._request);
  }
  updateStep(options) {
    return ProcessServer.updateStep(this._serverUri, options, this._request);
  }
  sendUpdates(options) {
    return ProcessServer.sendUpdates(this._serverUri, options, this._request);
  }
  findProcesses(options) {
    return ProcessServer.findProcesses(this._serverUri, options, this._request);
  }
  publishProcess(options) {
    return ProcessServer.publishProcess(this._serverUri, options, this._request);
  }
  validateProcess(options) {
    return ProcessServer.validateProcess(this._serverUri, options, this._request);
  }
  deleteCallbackAndHook(options) {
    return ProcessServer.deleteCallbackAndHook(this._serverUri, options, this._request);
  }
  createCallbackAndHook(options) {
    return ProcessServer.createCallbackAndHook(this._serverUri, options, this._request);
  }
  getMyImportedProcesses(options) {
    return ProcessServer.getMyImportedProcesses(this._serverUri, options, this._request);
  }
  getAverageStepDurations(options) {
    return ProcessServer.getAverageStepDurations(this._serverUri, options, this._request);
  }
  findExecutions(options) {
    return ProcessServer.findExecutions(this._serverUri, options, this._request);
  }
  findStuckExecutions(options) {
    return ProcessServer.findStuckExecutions(this._serverUri, options, this._request);
  }
  findExecution(options) {
    return ProcessServer.findExecution(this._serverUri, options, this._request);
  }
  tryStepAgain(options) {
    return ProcessServer.tryStepAgain(this._serverUri, options, this._request);
  }
  deleteProcess(options) {
    return ProcessServer.deleteProcess(this._serverUri, options, this._request);
  }
  importProcess(options) {
    return ProcessServer.importProcess(this._serverUri, options, this._request);
  }
  updateProcess(options) {
    return ProcessServer.updateProcess(this._serverUri, options, this._request);
  }
  deleteCompletedExecutions(_ref43) {
    var {
      token
    } = _ref43;
    return ProcessServer.deleteCompletedExecutions(this._serverUri, {
      token
    });
  }
  sendHangingSteps(options) {
    return ProcessServer.sendHangingSteps(this._serverUri, options, this._request);
  }
  getSettings(options) {
    return ProcessServer.getSettings(this._serverUri, options, this._request);
  }
  updateSettings(options) {
    return ProcessServer.updateSettings(this._serverUri, options, this._request);
  }
  getVaultStructureProcesses(options) {
    return ProcessServer.getVaultStructureProcesses(this._serverUri, options, this._request);
  }
  getSolutionGroups(options) {
    return ProcessServer.getSolutionGroups(this._serverUri, options, this._request);
  }
  getSolutionGroup(options) {
    return ProcessServer.getSolutionGroup(this._serverUri, options, this._request);
  }
  updateSolutionGroup(options) {
    return ProcessServer.updateSolutionGroup(this._serverUri, options, this._request);
  }
  deleteSolutionGroup(options) {
    return ProcessServer.deleteSolutionGroup(this._serverUri, options, this._request);
  }
  getExecutionShells(options) {
    return ProcessServer.getExecutionShells(this._serverUri, options, this._request);
  }
  getVaultExecutions(options) {
    return ProcessServer.getVaultExecutions(this._serverUri, options, this._request);
  }
  deleteExecutions(options) {
    return ProcessServer.deleteExecutions(this._serverUri, options, this._request);
  }
  getBlockWaitings(options) {
    return ProcessServer.getBlockWaitings(this._serverUri, options, this._request);
  }
  findBackgroundTasks(options) {
    return ProcessServer.findBackgroundTasks(this._serverUri, options, this._request);
  }
  getBackgroundTaskById(options) {
    return ProcessServer.getBackgroundTaskById(this._serverUri, options, this._request);
  }
  deleteBackgroundTask(options) {
    return ProcessServer.deleteBackgroundTask(this._serverUri, options, this._request);
  }
  reRunBackgroundTask(options) {
    return ProcessServer.reRunBackgroundTask(this._serverUri, options, this._request);
  }
}
var _default = exports.default = ProcessServer;