import { EXECUTE_PROCESS_STEP_TYPE } from '../../../constants'
import ExecuteProcessStepOptions from './components/stepOptions/ExecuteProcessStepOptions'
import ExecuteProcessStepAutomating from './components/stepAutomating/executeProcess'
import BasicUiHelper from './BasicUiHelper'
import StringHelper from '../../../shared-helpers/StringHelper'

class ExecuteProcessUiHelper extends BasicUiHelper {
  regex() {
    return EXECUTE_PROCESS_STEP_TYPE
  }

  name() {
    return 'Execute Process'
  }

  canTryAgain() {
    return false
  }

  stepOptions() {
    return ExecuteProcessStepOptions
  }

  // we don't want a submit button
  getStepButtons() {
    return []
  }

  getOutputKeys() {
    return {
      title: 'Child Execution',
      schema: {
        executionId: {
          type: 'Execution',
          processIdStepOption: 'processId',
        },
      },
    }
  }

  stepAutomating() {
    return ExecuteProcessStepAutomating
  }

  getOutputOptions(step) {
    const executionOutputs = step.stepOptions.dontWait
      ? []
      : step.stepOptions.childOutputs.map((child) =>
          child.executionOutput.map((output) => ({
            id: JSON.stringify({
              step: child.childStep._id,
              key: StringHelper.slugify(output.key),
            }),
            name: `Execution Output -> ${output.display}`,
            textValue: `{${step._id}.result.${StringHelper.slugify(output.key)}}`,
            type: 'string',
          })),
        )

    return [
      {
        id: JSON.stringify({ step: step._id, key: '$c_executionUrl' }),
        name: 'Execution URL',
        textValue: `{${step._id}.$c_executionUrl}`,
        type: 'string',
      },
      ...executionOutputs.flat(),
    ]
  }
}

export default ExecuteProcessUiHelper
