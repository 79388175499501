import _ from 'underscore'
import isArray from 'lodash/isArray'
import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Loading } from 'lib/acromyrmex'
import { useSelector } from 'react-redux'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import { Row, Col } from '../../../utility/UiComponents'
import breadcrumbsConfig from './breadcrumbsConfig'
import { userHasRole } from '../../../shared-helpers/ObjectHelper';

function Breadcrumbs() {
  const breadcrumbs = useBreadcrumbs(breadcrumbsConfig)

  const trimmedBreadcrumbs =
    breadcrumbs.length > 2
      ? breadcrumbs.slice(1, breadcrumbs.length - 1)
      : breadcrumbs.slice(1, breadcrumbs.length)

  return (
    <div
      style={{
        color: '#8c8c8c',
        fontSize: '0.8em',
      }}
    >
      {trimmedBreadcrumbs.map(({ breadcrumb, match }, index) => (
        <>
          <NavLink
            key={match.pathname}
            to={match.pathname}
            style={{ color: 'inherit' }}
          >
            {breadcrumb}
          </NavLink>
          {index !== trimmedBreadcrumbs.length - 1 && <span> / </span>}
        </>
      ))}
    </div>
  )
}

function ContentSection({
  noContentPadding,
  loadingText,
  loading,
  content,
  fillHeight,
}) {
  return (
    <div
      className="content-col"
      style={{
        ...(noContentPadding ? { padding: 0 } : {}),
        ...(fillHeight ? { height: '100%' } : {}),
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      {loading && <Loading text={loadingText} />}
      {!loading && content}
    </div>
  )
}

ContentSection.propTypes = {
  content: PropTypes.node,
  noContentPadding: PropTypes.bool,
  fillHeight: PropTypes.bool,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
}

ContentSection.defaultProps = {
  content: null,
  loading: false,
  loadingText: null,
  noContentPadding: false,
  fillHeight: false,
}

function PageLayout(props = {}) {
  const { currentUser } = useSelector((state) => state.users)
  const {
    footer,
    header,
    loading,
    loadingText,
    headerButtons,
    leftSidebar,
    rightSidebar,
    content,
    noContentPadding,
    description,
    maxWidth,
    width,
  } = props
  const singleStepUser = userHasRole(currentUser, 'single-step');

  const elements = []

  if (header) {
    elements.push(header)
  }

  let contentWidth = 12
  if (leftSidebar) {
    contentWidth -= 3
  }

  if (rightSidebar) {
    contentWidth -= 3
  }

  const styles = { height: '100%', width: width || '95%' }

  if (maxWidth) {
    styles.maxWidth = `${maxWidth}px`
  }

  const finalContent = isArray(content) ? (
    content.map((c, i) => (
      <ContentSection
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        content={c}
        noContentPadding={noContentPadding}
        loadingText={loadingText}
        loading={loading}
      />
    ))
  ) : (
    <ContentSection
      content={content}
      noContentPadding={noContentPadding}
      loadingText={loadingText}
      loading={loading}
      fillHeight
    />
  )

  const showBreadcrumbs = currentUser && !singleStepUser

  return (
    <div className="container box-wrapper gapped-grid" style={styles}>
      <div className="content-row box-fixed-header">
        {showBreadcrumbs && <Breadcrumbs />}
        {header && _.isString(header) && (
          <div style={{ float: 'left' }}>
            <div className="clearfix">
              <h4 style={{ float: 'left' }}>{header}</h4>
            </div>
            {description && (
              <div className="clearfix">
                <p>{description}</p>
              </div>
            )}
          </div>
        )}
        {header && _.isString(header) && headerButtons && (
          <div style={{ float: 'right', marginTop: '-11px' }}>
            {headerButtons}
          </div>
        )}
        {header && !_.isString(header) && header}
      </div>
      <Row className="box-rest">
        {leftSidebar && (
          <Col xs={12} sm={3} style={{ height: '100%' }}>
            <div
              className="content-col"
              style={{
                overflowY: 'auto',
                overflowX: 'hidden',
                maxHeight: '100%',
              }}
            >
              {leftSidebar}
            </div>
          </Col>
        )}
        {content && (
          <Col
            xs={12}
            sm={contentWidth}
            style={{ height: '100%' }}
            className="content-section"
          >
            {finalContent}
          </Col>
        )}
        {rightSidebar && (
          <Col xs={12} sm={3} style={{ height: '100%' }}>
            <div
              className="content-col"
              style={{
                overflowY: 'auto',
                overflowX: 'hidden',
                maxHeight: '100%',
              }}
            >
              {rightSidebar}
            </div>
          </Col>
        )}
      </Row>

      {footer && <Row className="content-row box-fixed-footer">{footer}</Row>}
    </div>
  )
}

PageLayout.propTypes = {
  footer: PropTypes.node,
  header: PropTypes.node,
  description: PropTypes.node,
  headerButtons: PropTypes.node,
  leftSidebar: PropTypes.node,
  rightSidebar: PropTypes.node,
  content: PropTypes.node,
  noContentPadding: PropTypes.bool,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  maxWidth: PropTypes.number,
}

PageLayout.defaultProps = {
  maxWidth: null,
  footer: null,
  header: null,
  description: null,
  headerButtons: null,
  leftSidebar: null,
  rightSidebar: null,
  content: null,
  loading: false,
  loadingText: null,
  noContentPadding: false,
}

export default PageLayout
