import BasicUiHelper from './BasicUiHelper'
import TerminateExecutionStepSkipped from './components/stepSkipped/TerminateExecutionStepSkipped'
import { TERMINATE_EXECUTION_STEP_TYPE } from '../../../constants'
import TerminateExecutionStepOptions from './components/stepOptions/TerminateExecutionStepOptions'

class TerminateExecutionUiHelper extends BasicUiHelper {
  stepOptions() {
    return TerminateExecutionStepOptions
  }

  canTryAgain() {
    return true
  }

  regex() {
    return TERMINATE_EXECUTION_STEP_TYPE
  }

  name() {
    return 'Terminate Execution'
  }

  stepSkipped() {
    return TerminateExecutionStepSkipped
  }
}

export default TerminateExecutionUiHelper
