/* eslint-disable react/no-find-dom-node */
/**
 * Root component for the Show Execution page.
 *
 * This component is responsible for:
 *  - Loading the execution data
 *  - Rendering Loading, Empty and Error views
 *  - Rendering the ExecutionView component
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loading } from 'lib/acromyrmex';
import { toast } from 'react-toastify';
import _ from 'underscore';
import includes from 'lodash/includes';
import { Row, Alert } from '../../../utility/UiComponents';
import {
  loadExecution as loadExecutionAction,
  createExecution as createExecutionAction,
  updateExecution as updateExecutionAction,
} from '../../../actions/executions';
import { loadEnabledIntegrations as loadEnabledIntegrationsAction } from '../../../actions/enabledIntegrations';
import { loadUsers as loadUsersAction } from '../../../actions/users';
import { loadVaultStructure as loadVaultStructureAction } from '../../../actions/vault';
import EmptyView from '../../shared/EmptyView';
import ExecutionView from './ExecutionView';

class ShowExecution extends React.Component {
  static renderLoading() {
    return (
      <div className="container" style={{ height: '100%' }}>
        <div className="box-wrapper">
          <Row className="content-row box-rest" style={{ marginTop: '10px' }}>
            <Alert variant="info" className="pull-left">
              <Loading />
              Loading Execution...
            </Alert>
          </Row>
        </div>
      </div>
    );
  }

  static renderEmpty() {
    return (
      <EmptyView
        header="Execution not found."
        content="This execution may have been deleted."
        button={{
          pathname: '/processes/active',
          content: 'Go to Active Processes',
        }}
      />
    );
  }

  static renderError() {
    return (
      <Row className="content-row">
        <Alert variant="danger" className="pull-left">
          <i className="fa fa-exclamation-circle" />
          This execution is missing a process, was it deleted?
        </Alert>
      </Row>
    );
  }

  onSubscribeUser = this.onSubscribeUser.bind(this);

  componentDidMount() {
    const {
      loadExecution,
      users,
      loadUsers,
      params: { executionId },
      currentUser,
      loadEnabledIntegrations,
    } = this.props;

    if (!currentUser) {
      return;
    }

    loadExecution(executionId).then(() => {
      this.onExecutionLoaded();
    });

    this.startPolling();

    if (!users || !users.length) {
      loadUsers();
    }

    loadEnabledIntegrations();
  }

  componentDidUpdate(prevProps) {
    const { params, loadExecution, currentUser, execution } = this.props;

    // Check if executionId has changed
    if (params.executionId !== prevProps.params.executionId) {
      if (!currentUser) {
        return;
      }

      // Stop previous polling
      this.stopPolling();

      // Start loading new execution with loading indicator
      loadExecution(params.executionId, { showLoading: true }).then(() => {
        this.onExecutionLoaded();
      });

      // Start polling for new execution
      this.startPolling();
    }

    // Update document title if execution name or number has changed
    if (
      execution &&
      prevProps.execution &&
      (execution.execution !== prevProps.execution.execution ||
        execution.process.name !== prevProps.execution.process.name)
    ) {
      document.title = `Contuit | ${execution.process.name} #${execution.execution}`;
    }
  }

  componentWillUnmount() {
    document.title = 'Contuit';
    this.stopPolling();
  }

  onExecutionLoaded() {
    const {
      execution,
      loadExecution,
      loadVaultStructure,
      vault,
      parentExecution,
    } = this.props;

    if (execution.parent && !parentExecution) {
      loadExecution(execution.parent, { parent: true });
    }

    const vaultTypes = [
      'vault-input',
      'auto-vault-create',
      'vault-create',
      'vault-query',
    ];
    const vaults = new Set();
    execution.process.steps.forEach((step) => {
      if (includes(vaultTypes, step.stepType)) {
        vaults.add(step.stepOptions.slug);
      }
    });

    vaults.forEach((slug) => {
      if (!vault.structures[slug]) {
        loadVaultStructure(slug, {}, true);
      }
    });
  }

  onSubscribeUser(user) {
    const { execution, updateExecution } = this.props;

    const existingUser = _.find(user, { _id: user._id });
    if (existingUser) {
      toast.success('User is already added');
      return;
    }

    // add the user to the execution's list and update it
    execution.users.push(user);
    updateExecution(execution);
  }

  loadExecutionData = () => {
    const { loadExecution, params } = this.props;
    const { executionId } = params;

    loadExecution(executionId, { showLoading: false })
      .then((executionData) => {
        // If the execution is complete, stop polling
        if (executionData && executionData.progress === 1) {
          this.stopPolling();
        } else {
          this.onExecutionLoaded();
        }
      })
      .catch((error) => {
        console.error('Error loading execution data:', error);
        // If the execution is not found, stop polling
        if (error.status === 404) {
          this.stopPolling();
        }
      });
  };

  startPolling() {
    this.pollingInterval = setInterval(this.loadExecutionData, 5000); // Poll every 5 seconds (adjust the interval as needed)
  }

  stopPolling() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
      this.pollingInterval = null;
    }
  }

  render() {
    const {
      execution,
      parentExecution,
      isLoading,
      currentUser,
      routes,
      params,
      vault,
      integrationOptions,
      createExecution,
      isCreating,
    } = this.props;

    // Show loading indicator when loading and no execution data
    if (isLoading) {
      return ShowExecution.renderLoading();
    }

    // Show empty state if not loading and no execution data
    if (!isLoading && !execution) {
      return ShowExecution.renderEmpty();
    }

    // Show error if execution has no process
    if (execution && !execution.process) {
      return ShowExecution.renderError();
    }

    return (
      <ExecutionView
        key={execution._id}
        params={params}
        routes={routes}
        vault={vault}
        currentUser={currentUser}
        execution={execution}
        parentExecution={parentExecution}
        onSubscribeUser={this.onSubscribeUser}
        integrationOptions={integrationOptions}
        createExecution={createExecution}
        isCreating={isCreating}
      />
    );
  }
}

// Define property types
ShowExecution.propTypes = {
  params: PropTypes.shape({
    executionId: PropTypes.string.isRequired,
  }).isRequired,
  integrationOptions: PropTypes.shape().isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  execution: PropTypes.shape(),
  currentUser: PropTypes.oneOfType([PropTypes.shape(), PropTypes.bool]),
  vault: PropTypes.shape().isRequired,
  users: PropTypes.arrayOf(PropTypes.shape()),
  loadExecution: PropTypes.func.isRequired,
  updateExecution: PropTypes.func.isRequired,
  loadUsers: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadEnabledIntegrations: PropTypes.func.isRequired,
  loadVaultStructure: PropTypes.func.isRequired,
  isCreating: PropTypes.bool.isRequired,
  createExecution: PropTypes.func.isRequired,
  parentExecution: PropTypes.shape(),
};

ShowExecution.defaultProps = {
  execution: null,
  currentUser: null,
  users: [],
  parentExecution: {},
};

const mapStateToProps = (state) => ({
  execution: state.processes.execution.value,
  integrations: state.enabledIntegrations.enabledIntegrations.rows,
  integrationOptions: state.enabledIntegrations.integrationOptions,
  vault: state.vault,
  isLoading: state.processes.execution.isLoading,
  error: state.processes.execution.error,
  isCreating:
    state.processes.process.isCreating || state.processes.process.isUpdating,
  currentUser: state.users.currentUser,
  users: state.users.users.rows,
  parentExecution: state.processes.execution.parent,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadExecution: loadExecutionAction,
      loadUsers: loadUsersAction,
      loadEnabledIntegrations: loadEnabledIntegrationsAction,
      updateExecution: updateExecutionAction,
      createExecution: createExecutionAction,
      loadVaultStructure: loadVaultStructureAction,
    },
    dispatch,
  );

const ShowExecutionWithState = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShowExecution);

const ShowExecutionWrapper = (props) => {
  const params = useParams();

  return <ShowExecutionWithState params={params} {...props} />;
};

export default ShowExecutionWrapper;
