import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import Collapsible from 'react-collapsible';
import { Nav } from '../../utility/UiComponents';
import NavigationList from './NavigationList';
import { userHasRole } from '../../shared-helpers/ObjectHelper';

const hasActiveChild = (children) => {
  let active = false;

  children.forEach((obj) => {
    if (obj.props.to && obj.props.to.pathname && window.location) {
      if (
        window.location.toString().includes(obj.props.to.pathname)
        || obj.props.to.pathname === window.location.pathname
      ) {
        active = true;
      }
    }
    if (obj.props.title) {
      active = active || hasActiveChild(obj.props.children);
    }
  });

  return active;
};

function NavTreeItem({ icon, title, children }) {
  return (
    <li className="side-bar-subtree">
      <Collapsible
        trigger={(() => (
          <div>
            <i className={icon ? `fa fa-fw fa-${icon}` : ''} />
            {' '}
            {title}
          </div>
        ))()}
        transitionTime={200}
        triggerClassName="nav-closed"
        triggerOpenedClassName="nav-open"
        open={hasActiveChild(children)}
      >
        <ul className="list">{children}</ul>
      </Collapsible>
    </li>
  );
}

NavTreeItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
};

NavTreeItem.defaultProps = {
  icon: '',
};

function NavListItem(props, manager) {
  const {
    title, links, path, external, icon,
  } = props;
  const children = !links
    ? ''
    : links.filter((i) => manager || !i.manager).map((link) => NavListItem(link));

  if (children) {
    return (
      <NavTreeItem key={title} title={title} icon={icon}>
        {children}
      </NavTreeItem>
    );
  }

  if (external) {
    return (
      <Nav.Item key={title} href={external} target="_blank" as="li">
        <a>
          <i className={icon ? `fa fa-fw fa-${icon}` : ''} />
          {' '}
          {title}
        </a>
      </Nav.Item>
    );
  }

  return (
    <LinkContainer key={title} to={path}>
      <Nav.Item as="li">
        <a>
          {' '}
          <i className={icon ? `fa fa-fw fa-${icon}` : ''} />
          {' '}
          {title}
        </a>
      </Nav.Item>
    </LinkContainer>
  );
}

NavListItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  path: PropTypes.string,
  external: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape()),
};

NavListItem.defaultProps = {
  path: '',
  external: '',
  icon: '',
  links: [],
};

function Navigation() {
  const location = useLocation();
  const { currentUser } = useSelector((state) => state.users);
  const customer = currentUser.contuitClient;
  const manager = userHasRole(currentUser, 'manager') || userHasRole(currentUser, 'admin');
  const singleStepUser = userHasRole(currentUser, 'single-step');

  // filters out non manager links if we are not manager
  const list = NavigationList.filter((i) => {
    if (manager) {
      return true;
    }

    if (singleStepUser) {
      return i.singleStepUser;
    }

    return !i.manager;
  }).map((item) => NavListItem(item, manager));

  return (
    <div className="side-bar">
      <div className="brand">
        <img src="/images/logo_white.png" alt="logo" />
      </div>
      {customer && (
        <div className="menu-customer">
          <div className="customer-name">{customer.name}</div>
        </div>
      )}
      <div className="navigation">
        <Nav as="ul">{list}</Nav>
      </div>
    </div>
  );
}

export default Navigation;
