import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { LinkContainer } from 'react-router-bootstrap'
import { useParams } from 'react-router'

import PageLayout from '../../shared/Layout/PageLayout'
import ShowVaultDataTable from './ShowVaultDataTable'
import AddButton from '../../shared/form/AddButton'

const ShowVault = (props) => {
  const { currentUser, routes } = props
  const { vaultStructureId } = useParams()

  return (
    <PageLayout
      header="The Vault"
      fullWidth
      headerButtons={
        <LinkContainer to={{ pathname: '/vault/new' }}>
          <AddButton size="sm" label="Add New Structure" />
        </LinkContainer>
      }
      noContentPadding
      content={
        <ShowVaultDataTable
          currentUser={currentUser}
          initialStructure={vaultStructureId}
        />
      }
      routes={routes}
      currentUser={currentUser}
    />
  )
}

ShowVault.propTypes = {
  currentUser: PropTypes.shape().isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
})

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ShowVault)
