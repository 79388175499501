import { useState, useEffect } from 'react'

function useFetch(asyncFetch, query, defaultValue) {
  const [data, setData] = useState(defaultValue)
  const [retryCount, setRetryCount] = useState(0)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        setError(null) // Reset error before fetching
        const resultData = await asyncFetch(query)
        setData(resultData)
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [retryCount, JSON.stringify(query)]) // Serialize query for dependency comparison

  const retry = () => {
    setRetryCount((prevRetryCount) => prevRetryCount + 1)
    return true
  }

  return [data, loading, error, retry]
}

export default useFetch
