import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LinkContainer } from 'react-router-bootstrap';
import { useParams } from 'react-router';
import { Button } from '../../utility/UiComponents';
import PageLayout from '../shared/Layout/PageLayout';
import ShowBackgroundTaskTable from './ShowProcess/ShowBackgroundTasksTable';

const ShowBackgroundTasks = ({ routes, currentUser }) => {
  const params = useParams();

  return (
    <PageLayout
      header="Background Tasks"
      routes={routes}
      params={params}
      fullWidth
      currentUser={currentUser}
      headerButtons={
        <LinkContainer to={{ pathname: '/dashboard' }}>
          <Button variant="default" size="small" className="pull-right">
            View Dashboard
          </Button>
        </LinkContainer>
      }
      noContentPadding
      content={<ShowBackgroundTaskTable currentUser={currentUser} />}
    />
  );
};

ShowBackgroundTasks.propTypes = {
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired,
  }).isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  params: PropTypes.shape().isRequired,
};

ShowBackgroundTasks.defaultProps = {};

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShowBackgroundTasks);
