// import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { Loading, LoadingButton } from 'lib/acromyrmex';
import TextInput from 'lib/acromyrmex/TextInput';
import CheckboxInput from 'lib/acromyrmex/CheckboxInput';
import {
  getShowKeys,
  getDeleteKeys,
  isDynamicEndpoint,
  getMaskKeys
} from '../../shared-helpers/ApiHelper';
import { Form, Row, Col } from '../../utility/UiComponents';
import { requiredValidator } from '../../utility/formValidators';
import { loadStructureList as loadStructureListAction } from '../../actions/vault';
import UserPassCredentialForm from './CredentialForms/UserPassCredentialForm';
import ConnectwiseCredentialForm from './CredentialForms/Connectwise';
import OauthCredentialForm from './CredentialForms/OAuth';
import LogicMonitorCredentialForm from './CredentialForms/LogicMonitor';
import UmbrellaCredentialForm from './CredentialForms/Umbrella';
import GenericCredentialForm from './CredentialForms/GenericCredentialForm';
import CrushBankCredentialForm from './CredentialForms/CrushBank';

class EnableIntegrationForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    isCreating: PropTypes.bool.isRequired,
    integration: PropTypes.shape(),
    edit: PropTypes.bool,
    valid: PropTypes.bool,
    currentUser: PropTypes.shape().isRequired,
    // enabledIntegrations: PropTypes.arrayOf(PropTypes.shape()),
    formValues: PropTypes.shape()
  };

  static defaultProps = {
    edit: false,
    valid: false,
    onSubmit: () => {},
    integration: {},
    formValues: {}
    // enabledIntegrations: [],
  };

  getIntegrationForm() {
    const { integration, currentUser, formValues } = this.props;
    const integrationSlug = integration['x-integration-slug'];

    switch (integrationSlug) {
      case 'cisco-find-it':
      case 'confluence':
      case 'cw-automate':
        return <UserPassCredentialForm />;
      case 'connectwise':
        return <ConnectwiseCredentialForm />;
      case 'logic-monitor':
        return <LogicMonitorCredentialForm />;
      case 'umbrella':
        return <UmbrellaCredentialForm />;
      case 'crushbank':
        return <CrushBankCredentialForm />;

      // for all OAuth based systems we use the same view and pull some info from the integration
      case 'kaseya':
      case 'msgraph':
      case 'azure':
      case 'atlas':
      case 'salesforce':
        return (
          <OauthCredentialForm
            dynamicEndpoint={isDynamicEndpoint(integrationSlug)}
            displayKeys={getShowKeys(integrationSlug)}
            maskedKeys={getMaskKeys(integrationSlug)}
            currentUser={currentUser}
            display={integration.info.title}
            integrationKey={integration['x-integration-slug']}
            name={formValues.name}
            formData={formValues}
          />
        );
      default:
        return (
          <GenericCredentialForm
            dynamicEndpoint={isDynamicEndpoint(integrationSlug)}
            displayKeys={getShowKeys(integrationSlug)}
            maskedKeys={getDeleteKeys(integrationSlug)}
          />
        );
    }
  }

  showFooter() {
    const { integration } = this.props;
    switch (integration['x-integration-slug']) {
      case 'kaseya':
      case 'msgraph':
      case 'azure':
      case 'atlas':
      case 'salesforce':
        return false;
      default:
        return true;
    }
  }

  render() {
    const { handleSubmit, onSubmit, edit, isCreating, integration, valid, formValues } = this.props;

    if (!integration) {
      return <Loading />;
    }

    return (
      <div className="content-row content-row-padded process-form box-rest">
        <div className="container">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Field name="name" component={TextInput} label="Name" validate={requiredValidator} />
            <Row>{this.getIntegrationForm(formValues.name)}</Row>
            <Field
              name="defaultIntegration"
              component={CheckboxInput}
              label={`Default ${integration['x-integration-slug']} Integration`}
              type="checkbox"
            />
            {this.showFooter() && <hr style={{ margin: '5px 0' }} />}
            {this.showFooter() && (
              <Row>
                <Col xs={12} style={{ marginTop: 10 }}>
                  <LoadingButton
                    variant="primary"
                    disabled={!valid}
                    label={edit ? 'Save Changes' : 'Enable Integration'}
                    loading={isCreating}
                    className="pull-right"
                    loadingLabel={edit ? 'Saving changes' : 'Enabling Integration'}
                    type="submit"
                  />
                  {!valid && (
                    <span
                      className="text-danger pull-right"
                      style={{ marginRight: '5px', marginTop: '7px' }}
                    >
                      Check form validity!
                    </span>
                  )}
                </Col>
              </Row>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  structureList: state.vault.structureList.rows,
  isCreating:
    state.enabledIntegrations.enabledIntegrations.isCreating ||
    state.enabledIntegrations.enabledIntegrations.isUpdating,
  formValues: state.form[props.form || 'enableIntegrationForm']
    ? state.form[props.form || 'enableIntegrationForm'].values
    : {},
  currentUser: state.users.currentUser
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadStructureList: loadStructureListAction
    },
    dispatch
  );

const form = reduxForm({ form: 'enableIntegrationForm' })(EnableIntegrationForm);
export default connect(mapStateToProps, mapDispatchToProps)(form);
