import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { TextInput, TemplateInput } from 'lib/acromyrmex';
import { Button, Row, Col } from '../../../../../utility/UiComponents';
import { requiredValidator } from '../../../../../utility/formValidators';
import StringHelper from '../../../../../shared-helpers/StringHelper';

const TerminateExecutionFormRow = ({
  attr,
  fields,
  index,
  outputOptions,
  change,
  hideLabel,
  onChangeAttributeNeeded
}) => (
  <Row key={attr} className="struct-form-row" style={{ marginTop: '10px' }}>
    <Col xs={5}>
      <Field
        name={`${attr}.label`}
        component={TextInput}
        label="Label"
        validate={requiredValidator}
        onPaste={e => {
          const pastedText = e.clipboardData.getData('Text');
          const rows = pastedText.split('\n');

          if (rows.length > 1) {
            // don't add the pasted text to the value
            e.preventDefault();
            change(`${attr}.label`, rows[0]);
            change(`${attr}.key`, StringHelper.slugify(rows[0]));
            // remove the first element
            rows.shift();

            // add our other fields
            rows.forEach(r => {
              fields.push({
                label: r,
                key: StringHelper.slugify(r),
                value: ''
              });
            });
          }
        }}
        onChange={e => {
          change(`${attr}.key`, StringHelper.slugify(e.target.value));
        }}
        noLabel={hideLabel}
      />
    </Col>
    <Col xs={5}>
      <Field
        name={`${attr}.value`}
        component={TemplateInput}
        options={outputOptions}
        label="Value"
        validate={requiredValidator}
        enableEmpty
        noLabel={hideLabel}
        onChange={() => onChangeAttributeNeeded()}
        plainText
      />
    </Col>

    <Col xs={2} style={{ marginTop: '3px' }}>
      <Button className="pull-right btn-sm" bsStyle="danger" onClick={() => fields.remove(index)}>
        <i className="fa fa-trash" /> Remove
      </Button>
    </Col>
  </Row>
);

TerminateExecutionFormRow.propTypes = {
  attr: PropTypes.string.isRequired,
  fields: PropTypes.shape().isRequired,
  index: PropTypes.number.isRequired,
  change: PropTypes.func.isRequired,
  hideLabel: PropTypes.bool.isRequired,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChangeAttributeNeeded: PropTypes.func.isRequired
};

export default TerminateExecutionFormRow;
