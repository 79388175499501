import React, { Component } from 'react';
import { CheckboxInput, Loading, TemplateInput } from 'lib/acromyrmex';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Col,
  Row,
  Button,
  Accordion,
} from '../../../../../utility/UiComponents';
import { getIntegrationFromStepType } from '../../../../../utility/integrationHelpers';
import { INTEGRATION_STEP_TYPE } from '../../../../../constants';

class StepResults extends Component {
  showTimeoutMinutesField = () => {
    const { processStep } = this.props;
    const { continueOnTimeout } = processStep.stepOptions;
    return Boolean(continueOnTimeout);
  };

  handleContinueOnErrorChange = (value) => {
    const { processStep, onChangeAttributeNeeded } = this.props;
    onChangeAttributeNeeded({
      ...processStep,
      stepOptions: {
        ...processStep.stepOptions,
        continueOnError: value,
      },
    });
  };

  handleContinueOnTimeoutChange = (value) => {
    const { processStep, onChangeAttributeNeeded } = this.props;
    onChangeAttributeNeeded({
      ...processStep,
      stepOptions: {
        ...processStep.stepOptions,
        continueOnTimeout: value,
      },
    });
  };

  handleContinueOnTimeoutMinutesChange = (value) => {
    const { processStep, onChangeAttributeNeeded } = this.props;
    onChangeAttributeNeeded({
      ...processStep,
      stepOptions: {
        ...processStep.stepOptions,
        continueOnTimeoutMinutes: value,
      },
    });
  };

  handleTerminateExecutionOnFailureChange = (value) => {
    const { processStep, onChangeAttributeNeeded } = this.props;
    onChangeAttributeNeeded({
      ...processStep,
      stepOptions: {
        ...processStep.stepOptions,
        terminateExecutionOnError: value,
      },
    });
  };

  handleAllowRetryChange = (value) => {
    const { processStep, onChangeAttributeNeeded } = this.props;
    onChangeAttributeNeeded({
      ...processStep,
      stepOptions: {
        ...processStep.stepOptions,
        allowRetry: value,
      },
    });
  };

  addErrorHandlingBlocks = () => {
    const { addStep, fields, index, processStep } = this.props;
    addStep(fields, index + 1, {
      name: `If ${processStep.name} Error`,
      stepType: 'block-begin-v2',
      conditions: [
        {
          lhv: `{${processStep._id}.$c_errorMessage}`,
          conditionType: 10,
        },
      ],
    });
    addStep(fields, index + 2, {
      name: `End If ${processStep.name} Error`,
      stepType: 'block-end-v2',
    });
    addStep(fields, index + 3, {
      name: `If not ${processStep.name} Error`,
      stepType: 'block-begin-v2',
      conditions: [
        {
          lhv: `{${processStep._id}.$c_errorMessage}`,
          conditionType: 9,
        },
      ],
    });
    addStep(fields, index + 4, {
      name: `End If not ${processStep.name} Error`,
      stepType: 'block-end-v2',
    });
  };

  // Used for integration steps only
  findRequestType = (obj, operationId) => {
    // Directly navigate to the 'paths' object within 'spec'
    if (obj && obj.spec && obj.spec.paths) {
      const { paths } = obj.spec;
      // Iterate over each path in 'paths'
      for (let path in paths) {
        if (paths.hasOwnProperty(path)) {
          // Iterate over the methods (get, post, etc.) in each path
          for (let method in paths[path]) {
            if (paths[path].hasOwnProperty(method)) {
              // Check if the operationId matches
              if (paths[path][method].operationId === operationId) {
                return method; // Return the request type (e.g., 'get', 'post')
              }
            }
          }
        }
      }
    }

    return null;
  };

  renderRetryOption = () => {
    const { step, change, processStep, integrations } = this.props;
    const isIntegrationStepType = new RegExp(INTEGRATION_STEP_TYPE).test(
      processStep.stepType,
    );

    if (!isIntegrationStepType) return null;

    const operationId = processStep.stepType.split('[')[1].split(']')[0];
    const integration = integrations.find(
      (i) =>
        i.integrationName === getIntegrationFromStepType(processStep.stepType),
    );

    if (typeof processStep.stepOptions.allowRetry === 'undefined') {
      change(`${step}.stepOptions.allowRetry`, true);
    }

    if (this.findRequestType(integration, operationId) === 'get') {
      return null;
    }

    return (
      <Row style={{ marginTop: '5px' }}>
        <Col xs={12} md={6} style={{ padding: 0 }}>
          <Field
            name={`${step}.stepOptions.allowRetry`}
            component={CheckboxInput}
            label="Auto-retry on failure?"
            type="text"
            onChange={(a, value) => this.handleAllowRetryChange(value)}
          />
        </Col>
      </Row>
    );
  };

  render() {
    const { outputOptionsLoaded, step } = this.props;

    return (
      <Row style={{ marginBottom: 5 }}>
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>Configuration Result Options</Accordion.Header>
            <Accordion.Body>
              <Col style={{ padding: '0px' }}>
                {outputOptionsLoaded ? (
                  <div className="clearfix" style={{ marginBottom: '5px' }}>
                    <Row style={{ padding: 0 }}>
                      <Col xs={12} md={6} style={{ padding: 0 }}>
                        <Field
                          name={`${step}.stepOptions.continueOnError`}
                          component={CheckboxInput}
                          label="Continue on error?"
                          type="text"
                          onChange={(a, value) =>
                            this.handleContinueOnErrorChange(value)
                          }
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <Button
                          variant="primary"
                          size="xs"
                          onClick={this.addErrorHandlingBlocks}
                        >
                          Add Error Handling Blocks
                        </Button>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '5px' }}>
                      <Col style={{ padding: '0px' }} xs={12} md={6}>
                        <Field
                          name={`${step}.stepOptions.continueOnTimeout`}
                          component={CheckboxInput}
                          label="Continue on timeout?"
                          type="text"
                          onChange={(a, value) =>
                            this.handleContinueOnTimeoutChange(value)
                          }
                        />
                      </Col>
                      {this.showTimeoutMinutesField() && (
                        <Col xs={12} md={6}>
                          <Field
                            name={`${step}.stepOptions.continueOnTimeoutMinutes`}
                            component={TemplateInput}
                            label="After minutes"
                            type="number"
                            onChange={(a, value) =>
                              this.handleContinueOnTimeoutMinutesChange(value)
                            }
                          />
                        </Col>
                      )}
                    </Row>
                    <Row style={{ marginTop: '5px' }}>
                      <Col style={{ padding: '0px' }} xs={12} md={6}>
                        <Field
                          name={`${step}.stepOptions.terminateExecutionOnError`}
                          component={CheckboxInput}
                          label="Terminate execution on error?"
                          type="text"
                          onChange={(a, value) =>
                            this.handleTerminateExecutionOnFailureChange(value)
                          }
                        />
                      </Col>
                    </Row>
                    {this.renderRetryOption()}
                  </div>
                ) : (
                  <Loading />
                )}
              </Col>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    );
  }
}

StepResults.propTypes = {
  outputOptionsLoaded: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  step: PropTypes.string.isRequired,
  processStep: PropTypes.shape().isRequired,
  onChangeAttributeNeeded: PropTypes.func.isRequired,
  fields: PropTypes.shape().isRequired,
  addStep: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  integrations: state.enabledIntegrations.enabledIntegrations.rows,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StepResults);
