import superagent from '../../../utility/request';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useLocalStorage } from 'react-use';
import exportFromJSON from 'export-from-json';
import { SelectInput, Icon } from 'lib/acromyrmex';
import { LinkContainer } from 'react-router-bootstrap';
import Loading from 'lib/acromyrmex/Loading';
import { toast } from 'react-toastify';
import { VaultServer, ProcessServer } from '@contuit-otf/sdk';
import { Row, Button } from '../../../utility/UiComponents';
import PagedDataTable from '../../shared/DataTable/PagedDataTable';
import useFetch from '../../shared/Hooks/useFetch';
import VaultStructureButtons from '../VaultStructureButtons';
import VaultEntryButtons from '../VaultEntryButtons';
import ConfirmModal from '../../shared/ConfirmModal';

const vaultServer = new VaultServer('/api/vault', superagent);
const processServer = new ProcessServer('/api/processes', superagent);

const loadStructureList = (token) =>
  vaultServer.loadVaultStructures({ token }).then((data) => data);

const loadStructureProcesses = (token, { slug }) =>
  slug
    ? processServer
        .getVaultStructureProcesses({ token, slug })
        .then((data) => data)
    : Promise.resolve([]);

const loadData = (
  token,
  { currentPage = 1, pageSize, filter, sort = '-dateUpdated', slug },
) => {
  if (!slug) {
    return Promise.reject(new Error('No slug'));
  }

  return vaultServer.loadVaultEntries({
    token,
    slug,
    query: {
      cp_limit: pageSize,
      cp_offset: (currentPage - 1) * pageSize,
      cp_sort: sort,
      ...filter,
    },
  });
};

const exportAllVaultData = async (token, struct, cols) => {
  const MAX_RECORDS = 5000;
  const BATCH_SIZE = 500;
  const exportData = [];
  let currentPage = 1;
  try {
    let total = Infinity; // Start with a placeholder total
    do {
      const { items, totalItemCount } = await loadData(token, {
        currentPage,
        pageSize: BATCH_SIZE,
        slug: struct.slug,
        sort: struct.sortAttribute
      });
      exportData.push(...items);
      total = totalItemCount;
      currentPage += 1;
    } while (exportData.length < Math.min(MAX_RECORDS, total));
    const exportedData = exportData.map(row =>
      struct.attributes
        .filter(a => a.isPrimary)
        .reduce((acc, v) => {
          if (v.key && v.key !== 'actions') {
            acc[v.display] = row[v.key];
          }

          return acc;
        }, {})
    );
    exportFromJSON({
      data: exportedData,
      fileName: `${struct.display} as of ${moment().format('YYYY-MM-DD')}`,
      exportType: 'csv'
    });
  } catch (error) {
    console.error('Error exporting vault data:', error);
  }
};

const ShowVaultDataTable = (props) => {
  const {
    initialStructure,
    currentUser: { token, contuitId },
  } = props;

  const [pageState, setPageState] = useState({});
  const [currentStructure, setCurrentStructure] = useLocalStorage(
    'vault-table-current-struct',
    initialStructure,
  );
  useEffect(() => {
    if (initialStructure) {
      setCurrentStructure(initialStructure);
    }
  }, [initialStructure]);
  const [runProcessLoading, setRunProcessLoading] = useState(false);
  const [deleteVaultEntriesLoading, setDeleteVaultEntriesLoading] =
    useState(false);
  const [runProcessMessage, setRunProcessMessage] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [tableColumns, setTableColumns] = useLocalStorage(
    `vault-table-columns-${currentStructure}`,
    undefined,
  );

  const [structures, structuresLoading, , reloadStructureList] = useFetch(
    // async fetch function
    loadStructureList.bind(this, token),
    // query, sent as the first parameter (third in this case) to the async fetch
    {},
    // default value
    [],
  );

  const [processes, processesLoading] = useFetch(
    // async fetch function
    loadStructureProcesses.bind(this, token),
    // query, sent as the first parameter (third in this case) to the async fetch
    { slug: currentStructure },
    // default value
    [],
  );

  const { currentPage, pageSize, filter, sort } = pageState;
  const [vaultData, vaultDataLoading, , vaultDataReloadFn] = useFetch(
    // async fetch function
    loadData.bind(this, token),
    // query, sent as the first parameter (third in this case) to the async fetch
    {
      slug: currentStructure,
      currentPage,
      pageSize: pageSize || 20,
      filter,
      sort,
    },
    // default value
    { items: [], totalItemCount: 0 },
  );

  if (structuresLoading) {
    return <Loading text="Loading Vault Structures" />;
  }

  const activeStruct = structures.find((s) => s.slug === currentStructure);

  if (!activeStruct && !structuresLoading) {
    setCurrentStructure('customers');
  }

  return (
    <>
      <PagedDataTable
        key={activeStruct ? activeStruct.slug : 'loading-table'}
        loading={!activeStruct || vaultDataLoading || deleteVaultEntriesLoading}
        csvTitle={`vault-${currentStructure}`}
        fetchCSVData={() =>
          loadData(token, {
            slug: currentStructure,
            currentPage,
            pageSize: pageSize || 20,
            filter,
            sort,
          })
        }
        title={
          <Row
            style={{
              padding: '3px',
              margin: 0,
              overflow: 'hidden',
              flexWrap: 'nowrap',
            }}
            className="vault-table-toggle"
          >
            <div style={{ width: '40%', maxWidth: '500px', float: 'left' }}>
              <SelectInput
                noLabel
                name="vault-structure"
                meta={{}}
                input={{
                  onChange: (value) => setCurrentStructure(value),
                  value: currentStructure,
                  onBlur: () => {},
                }}
                loading={structuresLoading}
                options={structures.map((s) => ({
                  id: s.slug,
                  name: s.display,
                }))}
              />
            </div>
            <div
              style={{ paddingLeft: '3px', paddingTop: '1px', float: 'left' }}
            >
              {activeStruct && (
                <VaultStructureButtons
                  struct={activeStruct}
                  createNew
                  onDelete={() => {
                    setCurrentStructure('customers');
                    reloadStructureList();
                  }}
                />
              )}
              {!activeStruct && <Loading />}
            </div>
          </Row>
        }
        selectable
        idAttribute="_id"
        totalCount={vaultData.totalItemCount}
        data={vaultData.items}
        toolbar={() => <span>{runProcessMessage}</span>}
        initialColumnShowList={tableColumns}
        onToggleColumns={(newColumnList) => {
          setTableColumns(newColumnList);
        }}
        actions={[
          {
            key: 'reload',
            style: 'primary',
            alwaysEnabled: true,
            display: (
              <span>
                <i className="fa fa-refresh" /> Reload
              </span>
            ),
            onClick: () => vaultDataReloadFn(),
          },
          {
            key: 'delete',
            style: 'danger',
            display: (
              <span>
                <Icon remove /> Delete
              </span>
            ),
            onClick: (selected) => {
              setDeleteVaultEntriesLoading(true);
              vaultServer
                .bulkDeleteEntries({
                  token,
                  slug: currentStructure,
                  entries: selected,
                  contuitId,
                })
                .then(
                  () =>
                    vaultDataReloadFn() &&
                    toast.success('Deleted Successfully'),
                )
                .catch((err) => {
                  const {
                    status,
                    response: { body },
                  } = err;

                  const msg =
                    status === 500
                      ? 'Unknown error deleting vault entries, please try again later.'
                      : body.message;

                  toast.error(msg);
                })
                .finally(() => setDeleteVaultEntriesLoading(false));
            },
          },
          {
            key: 'clear-all',
            style: 'danger',
            display: 'Clear All',
            alwaysEnabled: vaultData.items?.length > 0,
            onClick: () => setShowConfirmModal(true),
          },
          {
            key: 'run-process',
            style: 'primary',
            display: runProcessLoading ? 'Loading...' : 'Run Process',
            loading: runProcessLoading,
            multi: processes.reduce((result, p) => {
              const step = p.steps.find(
                (s) =>
                  s.stepOptions.vaultStructure === currentStructure ||
                  s.stepOptions.slug === currentStructure,
              );

              if (!step) {
                return result;
              }

              result.push({ display: p.name, value: `${p._id}.${step._id}` });
              return result;
            }, []),
            onClick: (selected, processIdStepId) => {
              const [processId, stepId] = processIdStepId.split('.');

              setRunProcessLoading(true);
              superagent
                .post(`/api/processes/executions?process=${processId}`)
                .set({ 'x-access-token': token })
                .send(selected.map((id) => ({ preFill: { [stepId]: { id } } })))
                .then(({ body }) => {
                  setRunProcessMessage(
                    <div>
                      Process{body.length ? 'es' : ''} created.{' '}
                      <LinkContainer
                        target="_blank"
                        to={{ pathname: `/processes/${processId}` }}
                      >
                        <Button size="xsmall" variant="link">
                          View Process
                        </Button>
                      </LinkContainer>
                    </div>,
                  );
                })
                .catch((e) => {
                  setRunProcessMessage(`Error: ${e.message}`);
                })
                .then(() => {
                  setRunProcessLoading(false);
                });
            },
          },
          {
            key: 'export-all',
            style: 'primary',
            display: 'Export All',
            alwaysEnabled: vaultData.items?.length > 0,
            onClick: () => exportAllVaultData(token, activeStruct, tableColumns)
          }
        ]}
        filterable
        columns={[
          {
            id: 'actions',
            display: 'Actions',
            width: 140,
            formatter: (row) => {
              const { original: entry } = row;

              return (
                <VaultEntryButtons
                  entry={entry}
                  slug={currentStructure}
                  viewDetails
                />
              );
            },
          },
          ...(activeStruct
            ? activeStruct.attributes.map((a) => ({
                key: a.key,
                display: a.display,
                hidden: !a.isPrimary,
                filterable: a.type !== 'file',
                sortable: a.type !== 'file',
                ...(a.type === 'dateTime' ? { type: 'timeago' } : {}),
              }))
            : []),
        ]}
        onFetchData={(newPageState) => {
          setPageState({ ...pageState, ...newPageState });
        }}
      />
      <ConfirmModal
        show={showConfirmModal}
        title="Confirmation Required: Permanent Action"
        message="Are you sure you want to permanently delete all vault entries? This action cannot be undone and will result in the permanent removal of all data associated with this structure. Please proceed with caution."
        onConfirm={() => {
          setDeleteVaultEntriesLoading(true);
          vaultServer
            .clearAllEntries({ token, slug: currentStructure })
            .then(() => {
              vaultDataReloadFn();
            })
            .catch((err) => {
              const {
                status,
                response: { body },
              } = err;
              const msg =
                err?.message ||
                'Unknown error clearing vault entries. Please try again later.';
              toast.error(msg);
            })
            .finally(() => {
              setDeleteVaultEntriesLoading(false);
              setShowConfirmModal(false);
            });
        }}
        onCancel={() => setShowConfirmModal(false)}
      />
    </>
  );
};

ShowVaultDataTable.propTypes = {
  initialStructure: PropTypes.string,
  currentUser: PropTypes.shape().isRequired,
};

ShowVaultDataTable.defaultProps = {
  initialStructure: null,
};

export default ShowVaultDataTable;
