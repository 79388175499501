import superagent from 'superagent';
import React, { useState } from 'react';
import { useLocalStorage } from 'react-use';
import PropTypes from 'prop-types';
import { ProcessServer } from '@contuit-otf/sdk';
import PagedDataTable from '../../shared/DataTable/PagedDataTable';
import useFetch from '../../../hooks/useFetch';
import { Button, ButtonGroup } from '../../../utility/UiComponents';
import request from '../../../utility/request';
import BackgroundTaskButtons from '../BackgroundTaskButtons';

function formatDuration(milliseconds) {
  const seconds = (milliseconds / 1000).toFixed(2);
  const minutes = (seconds / 60).toFixed(2);
  const hours = (minutes / 60).toFixed(2);
  const days = (hours / 24).toFixed(2);

  if (days > 1) {
    return `${days} day${days >= 2 ? 's' : ''}`;
  }

  if (hours > 1) {
    return `${hours} hour${hours >= 2 ? 's' : ''}`;
  }

  if (minutes > 1) {
    return `${minutes} minute${minutes >= 2 ? 's' : ''}`;
  }
  return `${seconds} second${seconds >= 2 ? 's' : ''}`;
}

const processServer = new ProcessServer('/api/processes', request);

const findBackgroundTasks = (
  token,
  {
    currentPage = 1,
    pageSize,
    filter,
    sort = '-updatedAt',
    showCompletedTasks = false,
  },
) => {
  const query = {
    hideCompleted: !showCompletedTasks,
    cp_limit: pageSize,
    cp_offset: (currentPage - 1) * pageSize,
    cp_sort: sort,
    ...filter,
  };

  return processServer.findBackgroundTasks({ token, query });
};

const ShowBackgroundTaskTable = ({ currentUser: { token } }) => {
  const [pageState, setPageState] = useState({});
  const [showCompletedTasks, setShowCompletedTasks] = useLocalStorage(
    'background-task-completed-tasks',
    false,
  );

  const { currentPage, pageSize, filter, sort } = pageState;

  const [tasks, tasksLoading, , taskReloadFn] = useFetch(
    findBackgroundTasks.bind(this, token),
    {
      currentPage,
      pageSize: pageSize || 20,
      filter,
      sort,
      showCompletedTasks,
    },
    { items: [], totalItemCount: 0 },
  );

  return (
    <PagedDataTable
      title="Background Tasks"
      csvTitle="background tasks"
      fetchCSVData={() =>
        findBackgroundTasks(token, {
          currentPage,
          filter,
          sort,
          showCompletedTasks,
        })
      }
      selectable
      idAttribute="_id"
      data={tasks.items}
      actions={[
        {
          key: 'reload',
          style: 'primary',
          alwaysEnabled: true,
          display: (
            <span>
              <i className="fa fa-refresh" /> Reload
            </span>
          ),
          onClick: () => taskReloadFn(),
        },
      ]}
      toolbar={() => (
        <div className="clearfix">
          <ButtonGroup style={{ marginRight: '5px' }}>
            <Button
              size="xs"
              variant="primary"
              active={!showCompletedTasks}
              onClick={() => setShowCompletedTasks(false)}
            >
              Active
            </Button>
            <Button
              size="xs"
              variant="primary"
              active={showCompletedTasks}
              onClick={() => setShowCompletedTasks(true)}
            >
              Completed
            </Button>
          </ButtonGroup>
        </div>
      )}
      onFetchData={(newPageState) => {
        if (tasksLoading) {
          return;
        }
        setPageState({ ...pageState, ...newPageState });
      }}
      loading={tasksLoading}
      totalCount={tasks ? tasks.totalItemCount : 0}
      columns={[
        { key: 'taskType', display: 'Task Type', width: 100 },
        {
          key: 'progress',
          display: 'Progress',
          type: 'percent',
          filterable: false,
          width: 100,
        },
        { key: 'taskName', display: 'Task Name', width: 200 },
        {
          key: 'status',
          display: 'Status',
          width: 100,
          formatter: (row) => {
            const status = row.original.status;
            const statusStyle =
              status === 'Completed'
                ? { color: '#006400', fontWeight: 'bold' }
                : status === 'Failed'
                  ? { color: '#800000', fontWeight: 'bold' }
                  : {};

            return <span style={statusStyle}>{status}</span>;
          },
        },
        { key: 'data', display: 'Data', type: 'json', width: 150 },
        { key: 'logs', display: 'Logs', type: 'json', width: 150 },
        { key: 'error', display: 'Error', width: 150 },
        { key: 'associatedModel', display: 'Associated Model', width: 150 },
        { key: 'associatedId', display: 'Associated ID', width: 200 },
        { key: 'updatedAt', display: 'Last Updated', type: 'timeago' },
        { key: 'createdAt', display: 'Created At', type: 'timeago' },
        {
          key: 'duration',
          display: 'Duration',
          width: 100,
          formatter: (row) =>
            formatDuration(
              new Date(row.original.updatedAt) -
                new Date(row.original.createdAt),
            ),
        },
        {
          id: 'actions',
          display: 'Actions',
          width: 65,
          formatter: (row) => {
            const { original: backgroundTask } = row;

            return (
              <BackgroundTaskButtons
                task={backgroundTask}
                onReRun={taskReloadFn}
              />
            );
          },
        },
      ]}
    />
  );
};

ShowBackgroundTaskTable.propTypes = {
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired,
  }).isRequired,
};

export default ShowBackgroundTaskTable;
