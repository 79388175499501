import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { HoverHelp } from 'lib/acromyrmex';
import { FieldArray } from 'redux-form';
import PropTypes from 'prop-types';
import { Row, Col, Accordion } from '../../../../../utility/UiComponents';
import TerminateExecutionFormList from '../terminateExecution/TerminateExecutionFormList';

// eslint-disable-next-line
class TerminateExecutionStepOptions extends React.Component {
  renderFields = ({ fields }) => {
    const { change, browser, outputOptions, onChangeAttributeNeeded } =
      this.props;

    return (
      <TerminateExecutionFormList
        fields={fields}
        outputOptions={outputOptions}
        change={change}
        hideLabel={browser.greaterThan.medium}
        onChangeAttributeNeeded={onChangeAttributeNeeded}
        hideCollectionFeatures
      />
    );
  };

  render() {
    const { fieldText } = this.props;

    return (
      <Row key={fieldText} style={{ marginBottom: 10 }}>
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              Terminate Execution Step Options
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col xs={5} className="text-center">
                  <strong>Label</strong>{' '}
                  <HoverHelp help="Execution output key" position="top" />
                </Col>

                <Col xs={5} className="text-center">
                  <strong>Value</strong>{' '}
                  <HoverHelp help="Execution output value" position="top" />
                </Col>
              </Row>
              <FieldArray
                name={`${fieldText}.stepOptions.executionOutput`}
                component={this.renderFields}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
    );
  }
}

// Define property types
TerminateExecutionStepOptions.propTypes = {
  browser: PropTypes.shape().isRequired,
  fieldText: PropTypes.string.isRequired,
  outputOptions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  change: PropTypes.func.isRequired,
  onChangeAttributeNeeded: PropTypes.func.isRequired,
};

TerminateExecutionStepOptions.defaultProps = {};

const mapStateToProps = (state) => ({
  browser: state.browser,
  structureList: state.vault.structureList.rows,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TerminateExecutionStepOptions);
