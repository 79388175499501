export const HOME_PAGE = process.env.REACT_APP_CONTUIT_HOME_PAGE
export const API_BASE_URL = process.env.REACT_APP_CONTUIT_HOST
export const HOST = window.location.origin
export const MOP = process.env.REACT_APP_MOP_SERVER

export const AUTH_SERVER = `/api/auth`
export const VAULT_SERVER = `/api/vault`
export const PROCESS_SERVER = `/api/processes`
export const SCHEDULER_SERVER = process.env.REACT_APP_SCHEDULER_SERVER

export const DATE_FORMAT = 'MMMM d yyyy, h:mm a'
export const TIME_FORMAT = 'h:mm:ss a'
export const DATE_FORMAT_NO_TIME = 'MMMM d yyyy'

export const SEND_SOCKETS = false

export const TEMPLATE_REGEX =
  /{([0-9a-fA-F]{24}|settings|execution|initiation)\.([^}]+)}/g

export const BASIC_STEP_TYPE = /^basic/
export const AUTO_VAULT_CREATE_STEP_TYPE = /^auto-vault-create/
export const BLOCK_BEGIN_STEP_TYPE = /^block-begin$/
export const BLOCK_END_STEP_TYPE = /^block-end$/
export const COMPUTATION_STEP_TYPE = /^computation$/
export const EXECUTE_PROCESS_STEP_TYPE = /^execute-process$/
export const LOOP_PROCESS_STEP_TYPE = /^loop-process$/
export const NARRATIVE_STEP_TYPE = /^narrative$/
export const SPECIAL_KASEYA_STEP_TYPE = /^special-kaseya$/
export const UPDATE_SOLUTION_SETTINGS_STEP_TYPE = /^update-solution-settings$/
export const VAULT_DELETE_STEP_TYPE = /^vault-delete$/
export const VAULT_MERGE_STEP_TYPE = /^vault-merge$/
export const VAULT_MERGE_V2_STEP_TYPE = /^vault-merge-v2$/
export const VAULT_QUERY_STEP_TYPE = /^vault-query$/
export const VAULT_QUERY_V2_STEP_TYPE = /^vault-query-v2$/
export const VAULT_REPLACE_STEP_TYPE = /^vault-replace$/
export const VAULT_SAVE_STEP_TYPE = /^vault-save$/
export const VAULT_INPUT_STEP_TYPE = /^vault-input$/
export const WEBEX_ACTION_LISTENER_STEP_TYPE = /^webex-action-listener$/
export const INTEGRATION_STEP_TYPE = /^i\([1-9a-zA-Z-]+\)\[[a-zA-Z-_]+\]$/
export const MANUAL_SELECT_STEP_TYPE = /^manual-select$/
export const MS_TEAMS_ACTION_LISTENER_STEP_TYPE = /^ms-teams-action-listener$/
export const MS_TEAMS_LISTENER_STEP_TYPE = /^ms-teams-listener$/
export const SLACK_LISTENER_STEP_TYPE = /^slack-listener$/
export const TRIGGER_STEP_TYPE = /^t\([a-zA-Z-]+\)\[[a-zA-Z-]+\]$/
export const VAULT_CREATE_STEP_TYPE = /^vault-create$/
export const INPUT_STEP_TYPE = /^input$/
export const COMPUTATION_V2_STEP_TYPE = /^computation-v2$/
export const STRING_FUNCTION_STEP_TYPE = /^string-function$/
export const JSON_PARSE_STEP_TYPE = /^json-parse$/
export const DELAY_STEP_TYPE = /^delay$/
export const DATE_FUNCTION_STEP_TYPE = /^date-function$/
export const CREATE_PDF_STEP_TYPE = /^create-pdf$/
export const BLOCK_BEGIN_V2_STEP_TYPE = /^block-begin-v2$/
export const BLOCK_END_V2_STEP_TYPE = /^block-end-v2$/
export const BEGIN_LOCK_BLOCK_STEP_TYPE = /^begin-lock-block$/
export const END_LOCK_BLOCK_STEP_TYPE = /^end-lock-block$/
export const TERMINATE_EXECUTION_STEP_TYPE = /^terminate-execution$/