import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { LoadingButton } from 'lib/acromyrmex';
import { ProcessServer } from '@contuit-otf/sdk';
import request from '../../utility/request';

const processServer = new ProcessServer('/api/processes', request);

const BackgroundTaskButtons = ({
  currentUser: { token },
  task,
  onReRun,
  pullRight,
}) => {
  const [isReRunning, setIsReRunning] = useState(false);

  const reRunTask = () => {
    setIsReRunning(true);
    processServer
      .reRunBackgroundTask({
        token,
        taskId: task._id,
      })
      .then(() => onReRun())
      .catch(() => {
        toast.error('Unknown error resuming task.');
      })
      .finally(() => {
        setIsReRunning(false);
      });
  };

  return (
    <div
      className={classNames('buttons-margin', {
        'pull-right': pullRight,
      })}
    >
      {task.status === 'Failed' && (
        <LoadingButton
          variant="primary"
          style={{ marginRight: 5 }}
          size="xsmall"
          loading={isReRunning}
          onClick={() => reRunTask()}
          loadingLabel=" "
          label="Re-run"
        />
      )}
    </div>
  );
};

BackgroundTaskButtons.propTypes = {
  task: PropTypes.shape().isRequired,
  onReRun: PropTypes.func,
  currentUser: PropTypes.shape({
    token: PropTypes.string.isRequired,
  }).isRequired,
  pullRight: PropTypes.bool,
};

BackgroundTaskButtons.defaultProps = {
  onReRun: () => {},
  pullRight: false,
};

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BackgroundTaskButtons);
