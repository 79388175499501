import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AuthenticationServer } from '@contuit-otf/sdk';
import AddButton from '../../shared/form/AddButton';
import ListApiKeys from './ListApiKeys';
import { addUser as addUserAction } from '../../../actions/users';
import request from '../../../utility/request';

const authServer = new AuthenticationServer('/api/auth', request);

class ShowApiKeys extends Component {
  static propTypes = {
    user: PropTypes.shape().isRequired,
    currentUser: PropTypes.shape().isRequired,

    // redux
    addUser: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  state = {};

  render() {
    const {
      user,
      addUser,
      currentUser: { token },
    } = this.props;

    return (
      <div>
        <h4>Showing Api Keys</h4>
        <ListApiKeys
          apiKeys={user.tenants.find(tenant => tenant.id === user.contuitId).apiKeys}
          onDeleteApiKeyClicked={(apiKeyId) =>
            authServer
              .deleteApiKey({ user, token, apiKeyId })
              .then((updatedUser) => {
                addUser(updatedUser);
              })
          }
        />
        <div>
          <AddButton
            size="sm"
            onClick={() => {
              authServer.addApiKey({ user, token }).then((updatedUser) => {
                addUser(updatedUser);
              });
            }}
            label="Add Api Key"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addUser: addUserAction,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(ShowApiKeys);
