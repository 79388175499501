import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { LoadingButton } from 'lib/acromyrmex';
import { Button } from '../../utility/UiComponents';
import {
  loadUser as loadUserAction,
  disableUser as disableUserAction,
  enableUser as enableUserAction,
  openRolesModal as openRolesModalAction,
} from '../../actions/users';
import ConfirmModal from '../shared/ConfirmModal';

const isCurrentUser = (currentUser, item) => currentUser._id === item._id;
const isDisabled = ({ userForAction, currentTenantId }) => {
  const targetTenant = userForAction.tenants.find(
    (tenant) => tenant.id === currentTenantId,
  );
  return targetTenant?.disabled;
};

const UserButtons = ({
  currentUser,
  item: userForAction,
  pullRight,
  isUpdating,
  loadUser,
  disableUser,
  enableUser,
  openRolesModal,
  loadUsers,
}) => {
  const [showConfirmDisable, setShowConfirmDisable] = useState(false);

  const enableDisable = isDisabled({
    userForAction,
    currentTenantId: currentUser.contuitId,
  })
    ? 'enable'
    : 'disable';

  return (
    <div
      className={classNames('buttons-margin', {
        'pull-right': pullRight,
      })}
    >
      <LinkContainer
        to={{
          pathname: `/admin/users/${userForAction._id}`,
        }}
      >
        <Button size="xs" variant="primary">
          View Profile
        </Button>
      </LinkContainer>

      {!isCurrentUser(currentUser, userForAction) && (
        <Button
          size="xs"
          variant="default"
          onClick={() => {
            loadUser(userForAction._id);
            openRolesModal();
          }}
        >
          Manage Roles
        </Button>
      )}

      {
        (!isCurrentUser(currentUser, userForAction),
        isDisabled({
          userForAction,
          currentTenantId: currentUser.contuitId,
        }) && (
          <span>
            <LoadingButton
              className="btn-success"
              style={{ marginRight: 5 }}
              size="xs"
              loading={isUpdating}
              loadingLabel="Enabling User"
              onClick={() => {
                loadUser(userForAction._id);
                setShowConfirmDisable(true);
              }}
              label={
                <span>
                  <i className="fa fa-plus" /> Enable
                </span>
              }
            />
          </span>
        ))
      }

      {!isCurrentUser(currentUser, userForAction) &&
        !isDisabled({
          userForAction,
          currentTenantId: currentUser.contuitId,
        }) && (
          <LoadingButton
            className="btn-danger"
            style={{ marginRight: 5 }}
            size="xsmall"
            loading={isUpdating}
            loadingLabel="Disabling User"
            onClick={() => {
              loadUser(userForAction._id);
              setShowConfirmDisable(true);
            }}
            label={
              <span>
                <i className="fa fa-ban" /> Disable
              </span>
            }
          />
        )}

      <ConfirmModal
        show={showConfirmDisable}
        title={`Confirm ${enableDisable}ing ${userForAction.name}?`}
        message={`Are you sure you want to ${enableDisable}e ${userForAction.name}?`}
        onConfirm={async () => {
          const currentTenantId = currentUser.contuitId;

          const updateUser = isDisabled({ userForAction, currentTenantId })
            ? () => enableUser(userForAction._id)
            : () => disableUser(userForAction._id);

          await updateUser();
          await loadUsers();
          setShowConfirmDisable(false);
        }}
        onCancel={() => {
          setShowConfirmDisable(false);
        }}
      />
    </div>
  );
};

UserButtons.propTypes = {
  pullRight: PropTypes.bool,
  isUpdating: PropTypes.bool.isRequired,
  item: PropTypes.shape().isRequired,
  disableUser: PropTypes.func.isRequired,
  enableUser: PropTypes.func.isRequired,
  currentUser: PropTypes.shape().isRequired,
  loadUser: PropTypes.func.isRequired,
  openRolesModal: PropTypes.func.isRequired,
  loadUsers: PropTypes.func.isRequired,
};

UserButtons.defaultProps = {
  pullRight: false,
};

const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser,
  isUpdating: state.users.user.isUpdating,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadUser: loadUserAction,
      disableUser: disableUserAction,
      enableUser: enableUserAction,
      openRolesModal: openRolesModalAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserButtons);
