import _ from 'underscore';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { LoadingButton } from 'lib/acromyrmex';
import { Button, ProgressBar } from '../../../utility/UiComponents';
import UserThumbnailList from '../../user/UserThumbnailList';
import ExecutionMarkdown from '../ExecutionMarkdown';
import ExecutionOverview from './ExecutionOverview';
import PageLayout from '../../shared/Layout/PageLayout';
import ReportBugModal from './ReportBugModal';
import ExecutionHistoryView from './ExecutionHistoryView';
import { userHasRole } from '../../../shared-helpers/ObjectHelper';

const ExecutionView = ({
  currentUser,
  routes,
  params,
  execution,
  parentExecution,
  onSubscribeUser,
  vault,
  createExecution,
  isCreating,
  integrationOptions,
}) => {
  const { users } = execution;
  const singleStepUser = userHasRole(currentUser, 'single-step');
  const [reportBugModalOpen, setReportBugModalOpen] = useState(false);

  return (
    <PageLayout
      params={params}
      routes={routes}
      fullWidth
      header={`${execution.process.name} #${execution.execution}`}
      headerButtons={
        <div style={{ display: 'flex' }}>
          {parentExecution && execution.parent && (
            <div style={{ paddingTop: '4px', paddingRight: '5px' }}>
              <LinkContainer
                to={{
                  pathname: `/processes/${parentExecution.process._id}/executions/${parentExecution._id}`,
                }}
              >
                <Button size="xs" variant="primary">
                  View Parent Execution
                </Button>
              </LinkContainer>
            </div>
          )}
          <div style={{ paddingTop: '4px', paddingRight: '5px' }}>
            <LoadingButton
              variant="success"
              size="xs"
              onClick={() => {
                createExecution(execution.process._id);
              }}
              loading={isCreating}
              loadingLabel="loading.."
              label={
                <span>
                  <i className="fa fa-play" />
                  {' Execute Again'}
                </span>
              }
            />
          </div>
          <div style={{ paddingTop: '4px', paddingRight: '5px' }}>
            <LinkContainer
              to={{
                pathname: `/processes/${execution.process._id}/edit`,
              }}
            >
              <Button size="xs" variant="primary">
                Edit Process
              </Button>
            </LinkContainer>
          </div>
          <ProgressBar
            now={execution.progress * 100}
            label={`${Math.round(execution.progress * 100)}%`}
            style={{ width: '150px', marginRight: '10px', marginTop: '5px' }}
          />
          <UserThumbnailList
            users={execution.users}
            userList={users}
            onUserSelected={onSubscribeUser}
            addButton
          />
          <div style={{ paddingTop: '4px', paddingLeft: '5px' }}>
            <LoadingButton
              variant="secondary"
              size="xs"
              onClick={() => setReportBugModalOpen(true)}
              loading={false}
              loadingLabel=""
              label={
                <span>
                  <i className="fa fa-bug" />
                  {' Report Bug'}
                </span>
              }
            />
          </div>
        </div>
      }
      description={
        execution.process.description && (
          <ExecutionMarkdown source={execution.process.description} />
        )
      }
      content={
        <>
          <ExecutionHistoryView
            execution={execution}
            parentExecution={parentExecution}
            vault={vault}
            currentUser={currentUser}
            integrationOptions={integrationOptions}
          />
          <ReportBugModal
            showModal={reportBugModalOpen}
            onHide={() => setReportBugModalOpen(false)}
            currentUser={currentUser}
            execution={execution}
          />
        </>
      }
      rightSidebar={
        singleStepUser ? undefined : <ExecutionOverview execution={execution} />
      }
    />
  );
};

ExecutionView.propTypes = {
  params: PropTypes.shape().isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currentUser: PropTypes.shape().isRequired,
  onSubscribeUser: PropTypes.func.isRequired,
  vault: PropTypes.shape().isRequired,
  parentExecution: PropTypes.shape(),
  execution: PropTypes.shape().isRequired,
  isCreating: PropTypes.bool.isRequired,
  createExecution: PropTypes.func.isRequired,
  integrationOptions: PropTypes.shape().isRequired,
};

ExecutionView.defaultProps = {
  parentExecution: null,
};

export default ExecutionView;
