import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Loading } from 'lib/acromyrmex';
import {
  Button,
  Modal,
  ListGroup,
  ListGroupItem,
  Alert,
} from '../../utility/UiComponents';
import {
  updateUser as updateUserAction,
  closeRolesModal as closeRolesModalAction,
  loadAvailableUserRoles as loadAvailableUserRolesAction,
} from '../../actions/users';

class ManageRolesModal extends React.Component {
  static removeItem(item, array) {
    const i = array.indexOf(item);
    if (i !== -1) {
      array.splice(i, 1);
    }
    return array;
  }

  static addItem(item, array) {
    if (!array.includes(item)) {
      array.push(item);
    }
    return array;
  }

  static renderModuleAccess(role) {
    const modules = role.modules.map((module) => (
      <div>
        {module.name}: {module.access.join(', ')}
      </div>
    ));
    return <div>{modules}</div>;
  }

  constructor(props) {
    super(props);

    this.state = {
      newRoles: [],
    };
  }

  componentWillMount() {
    const { loadAvailableUserRoles } = this.props;
    loadAvailableUserRoles();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !nextProps.loadingUser &&
      nextProps.user &&
      nextProps.rolesModalOpen &&
      !nextProps.isUpdatingUser
    ) {
      this.setState({ newRoles: [...nextProps.user.userRoles] });
    }

    if (nextProps.loadingUser) {
      this.setState({ newRoles: [] });
    }
  }

  handleRoleSelect(role) {
    const { newRoles } = this.state;
    const theNewRoles = newRoles.includes(role)
      ? ManageRolesModal.removeItem(role, newRoles)
      : ManageRolesModal.addItem(role, newRoles);
    this.setState({ newRoles: theNewRoles });
  }

  handleCancel() {
    const { closeRolesModal } = this.props;

    closeRolesModal();
  }

  handleSubmit() {
    const { user, updateUser, closeRolesModal } = this.props;
    const { newRoles } = this.state;

    // Find the correct tenant index
    const tenantIndex = user.tenants.findIndex(
      (tenant) => tenant.id === user.contuitId,
    );

    if (tenantIndex !== -1) {
      // Create a new user object with the updated roles
      const updatedUser = {
        ...user,
        tenants: user.tenants.map((tenant, index) =>
          index === tenantIndex ? { ...tenant, userRoles: newRoles } : tenant,
        ),
      };

      updateUser(user._id, updatedUser);
    }

    closeRolesModal();
  }

  renderRole(role) {
    const { newRoles } = this.state;
    return (
      <ListGroupItem
        key={role.name}
        active={newRoles.includes(role.name)}
        onClick={() => this.handleRoleSelect(role.name)}
        header={role.name}
      >
        {role.description}
        {/* {ManageRolesModal.renderModuleAccess(role)} */}
      </ListGroupItem>
    );
  }

  renderRoles() {
    const { availableRoles } = this.props;

    if (!availableRoles || availableRoles.length < 1) {
      return <p>There are no available user roles to choose from.</p>;
    }

    const roleList = availableRoles.map((role) => this.renderRole(role));

    return <ListGroup>{roleList}</ListGroup>;
  }

  renderTitle() {
    const { user, loadingUser, loadingRoles } = this.props;

    if (loadingUser || loadingRoles || !user) {
      return <Modal.Title>Role Management</Modal.Title>;
    }

    return <Modal.Title>Role Management for {user.name}</Modal.Title>;
  }

  renderBody() {
    const { user, loadingUser, loadingRoles } = this.props;

    if (loadingUser || loadingRoles) {
      return (
        <Alert variant="info">
          <Loading /> Loading user and roles...
        </Alert>
      );
    }

    if (!user) {
      return <Alert variant="danger">Missing user!</Alert>;
    }

    return this.renderRoles();
  }

  renderFooter() {
    const { user, loadingUser, loadingRoles } = this.props;

    if (loadingUser || loadingRoles || !user) {
      return <Button onClick={() => this.handleCancel()}>Cancel</Button>;
    }

    return (
      <div>
        <Button onClick={() => this.handleCancel()} size="sm">
          Cancel
        </Button>
        <Button
          onClick={() => this.handleSubmit()}
          variant="success"
          size="sm"
          style={{ marginLeft: 10 }}
        >
          Set Roles
        </Button>
      </div>
    );
  }

  render() {
    const { rolesModalOpen } = this.props;

    return (
      <div>
        <Modal show={rolesModalOpen} backdrop="static" size="large">
          <Modal.Header>{this.renderTitle()}</Modal.Header>
          <Modal.Body>{this.renderBody()}</Modal.Body>
          <Modal.Footer>{this.renderFooter()}</Modal.Footer>
        </Modal>
      </div>
    );
  }
}

ManageRolesModal.propTypes = {
  // Facts
  user: PropTypes.shape(),
  availableRoles: PropTypes.arrayOf(PropTypes.shape()).isRequired,

  // Status
  loadingUser: PropTypes.bool.isRequired,
  loadingRoles: PropTypes.bool.isRequired,
  isUpdatingUser: PropTypes.bool.isRequired,
  rolesModalOpen: PropTypes.bool.isRequired,

  // Actions
  updateUser: PropTypes.func.isRequired,
  closeRolesModal: PropTypes.func.isRequired,
  loadAvailableUserRoles: PropTypes.func.isRequired,
};

ManageRolesModal.defaultProps = {
  user: null,
};

const mapStateToProps = (state) => ({
  user: state.users.user.value,
  availableRoles: state.users.availableUserRoles.rows,
  loadingUser: state.users.user.isLoading,
  loadingRoles: state.users.availableUserRoles.isLoading,
  isUpdatingUser: state.users.user.isUpdating,
  rolesModalOpen: state.users.rolesModalOpen,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUser: updateUserAction,
      closeRolesModal: closeRolesModalAction,
      loadAvailableUserRoles: loadAvailableUserRolesAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ManageRolesModal);
