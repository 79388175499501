import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Button, Collapse } from '../../utility/UiComponents';

const ToggleIcon = ({ show, icon, badge }) => (
  <span className="fa-stack fa-sm">
    {show && <i className="fa fa-circle fa-stack-2x" />}
    <i
      className={classnames('fa', 'fa-stack-1x', icon, { 'fa-inverse': show })}
    />
    {badge > 0 && <span className="badge badge-notify">{badge}</span>}
  </span>
);

ToggleIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  badge: PropTypes.number,
  show: PropTypes.bool,
};

ToggleIcon.defaultProps = {
  badge: 0,
  show: false,
};

const TrundleRow = ({
  className,
  prefix,
  content,
  trundles,
  actions,
  suffix,
  alwaysOn,
  uiContainerOverflow,
  skippedSteps,
  stepErrored,
  executionStep
}) => {
  const [activeTrundle, setActiveTrundle] = useState(-1);
  const [showHiddenSteps, setShowHiddenSteps] = useState(false);

  return (
    <div className={`trundle-row ${className}`} style={{ display: 'flex' }}>
      {prefix.map((p, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i}>{p}</div>
      ))}

      <div
        style={{ flex: 1, overflow: uiContainerOverflow ? 'visible' : 'auto' }}
      >
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>{content}</div>
          {/* icons to toggle the trundles on and off */}
          {trundles.map((t, i) => {
            const currentTrundleActive = activeTrundle === i;

            return (
              <div key={t.icon} className="trundle-button">
                <Button
                  variant="link"
                  size="xs"
                  onClick={() =>
                    setActiveTrundle(currentTrundleActive ? -1 : i)
                  }
                >
                  <ToggleIcon
                    show={currentTrundleActive}
                    icon={`fa-${t.icon}`}
                  />
                </Button>
              </div>
            );
          })}
          {actions.map((a) => (
            <div key={a.icon}>
              <Button
                variant="link"
                size="xs"
                onClick={a.onClick}
                title={a.title}
              >
                <ToggleIcon
                  show={a.active === true}
                  icon={`fa-${a.icon}`}
                  badge={a.badge}
                />
              </Button>
            </div>
          ))}
        </div>
        {alwaysOn && (
          <div className="active-trundle always-on-trundle">{alwaysOn}</div>
        )}
        {stepErrored && skippedSteps.length > 0 && (
          <div style={{ marginTop: '5px', padding: '0 10px' }}>
            <strong>Execution Terminated: </strong>
            <span>{executionStep.error}</span>
            <div style={{ marginTop: '3px' }}>
              <Button
                bsSize="xs"
                bsStyle="primary"
                onClick={() => {
                  setShowHiddenSteps(!showHiddenSteps);
                }}
              >
                {showHiddenSteps ? 'Hide' : 'Show'} {skippedSteps.length}{' '}
                skipped step
                {skippedSteps.length > 1 ? 's' : ''}
              </Button>
            </div>
            <div>
              <Collapse in={showHiddenSteps}>
                <div>
                  {skippedSteps.map((skippedStep, i) => (
                    <div
                      key={skippedStep._id}
                      className="indented"
                      style={{ marginTop: '3px' }}
                    >
                      {i + 1}. {skippedStep.name}
                    </div>
                  ))}
                </div>
              </Collapse>
            </div>
          </div>
        )}
        <div className="active-trundle">
          {activeTrundle !== -1 && trundles[activeTrundle].body}
        </div>
      </div>
      {suffix.map((p, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={i}>{p}</div>
      ))}
    </div>
  );
};

TrundleRow.propTypes = {
  className: PropTypes.string,
  suffix: PropTypes.arrayOf(PropTypes.node),
  prefix: PropTypes.arrayOf(PropTypes.node),
  alwaysOn: PropTypes.node,
  trundles: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      body: PropTypes.node.isRequired,
    }),
  ),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    }),
  ),
  content: PropTypes.node.isRequired,
  skippedSteps: PropTypes.arrayOf(PropTypes.shape()),
  stepErrored: PropTypes.bool,
};

TrundleRow.defaultProps = {
  className: '',
  alwaysOn: null,
  trundles: [],
  actions: [],
  prefix: [],
  suffix: [],
  skippedSteps: [],
  stepErrored: false,
};

export { ToggleIcon };
export default TrundleRow;
