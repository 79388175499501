/* eslint-disable class-methods-use-this */
import React from 'react'
import _ from 'underscore'
import {
  getIntegrationFromStepType,
  getActionFromStepType,
} from '../../../utility/integrationHelpers'
import { INTEGRATION_STEP_TYPE } from '../../../constants'
import IntegrationStepOptions from './components/stepOptions/IntegrationStepOptions'
import BasicUiHelper from './BasicUiHelper'
import { propertiesToArray } from '../../../utility/TemplateHelper'
import {
  getOperation,
  getSuccessResponseAttributes,
} from '../../../shared-helpers/ApiHelper'
import useTemplateString from '../../../hooks/useTemplateString'

class IntegrationUiHelper extends BasicUiHelper {
  regex() {
    return INTEGRATION_STEP_TYPE
  }

  name() {
    return 'Integration'
  }

  canTryAgain() {
    return true
  }

  stepOptions() {
    return IntegrationStepOptions
  }

  getOutputKeys() {
    return {
      schema: {
        '.': {
          type: 'Table',
        },
      },
    }
  }

  getOptionSummary() {
    return [
      {
        key: '$c_stepType',
        title: 'Step Type'
      },
      {
        key: 'parameters',
        title: 'Parameters',
        default: [],
        formatter: (value, currentUser, execution) => (
          <table className="parameter-table">
            <tbody>
              {value.map(param => {
                const [outputString, error] = useTemplateString(
                  param.value,
                  currentUser,
                  execution,
                  'loading...'
                );
                return (
                  <tr>
                    <td>{param.name}</td>
                    <td>{outputString || error}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )
      }
    ];
  }

  getOutputOptions(step, { integrations }) {
    return IntegrationUiHelper.getOutputOptions(step, { integrations });
  }

  static getOutputOptions(step, { integrations }) {
    const integrationName = getIntegrationFromStepType(step.stepType);

    const integration =
      integrations &&
      integrations.find(item => item.spec['x-integration-slug'] === integrationName);

    if (!integration) {
      return [];
    }

    const action = getActionFromStepType(step.stepType);
    const operation = getOperation(integration.spec, action);

    return IntegrationUiHelper.getResponseAttributesForOperation(step, operation);
  }

  static getResponseAttributesForOperation(step, operation) {
    const responseAttributes = getSuccessResponseAttributes(operation);

    if (!responseAttributes) {
      return;
    }

    const responseAttributesCopy = JSON.parse(JSON.stringify(responseAttributes));

    const result = [];

    _.forEach(responseAttributesCopy, (attr, key) => {
      if (attr.type === 'object') {
        result.push(
          ...propertiesToArray(step._id, attr.properties, `.${key}`, `${attr.display || key} -> `)
        );
      } else {
        let { type } = attr;
        if (type === 'date-time') {
          type = 'date-time';
        } else if (type === 'array') {
          type = 'collection';
        }

        // console.log({ attr });
        result.push({
          id: JSON.stringify({ step: step._id, field: attr, key }),
          name: `${attr.display || key}`,
          textValue: `{${step._id}.${key}}`,
          properties: attr.items
            ? propertiesToArray(
                step._id,
                attr.items.properties,
                `.${key}`,
                `${attr.display || key} -> `
              )
            : propertiesToArray(step._id, attr.properties),
          type
        });
      }
    });

    if (result.length === 1 && result[0].type === 'collection') {
      result.push(BasicUiHelper.getLengthAttribute(step));
    }

    // Add the step output attribute
    result.push(...BasicUiHelper.getStepOutputAttribute(step));

    (step.stepOptions.dynamicProperties || []).forEach(attr =>
      result.push({
        id: JSON.stringify({ step: step._id, field: attr, key: attr.key }),
        name: attr.key.replace(/\./g, ' -> '),
        textValue: `{${step._id}.${attr.key}}`,
        type: attr.type
      })
    );

    return result;
  }
}

export default IntegrationUiHelper
