const createNestedObject = function createNestedObject(base, names, value) {
  // If a value is given, remove the last name and keep it for later:
  const lastName = arguments.length === 3 ? names.pop() : false

  // Walk the hierarchy, creating new objects where needed.
  // If the lastName was removed, then the last object is not set yet:
  for (let i = 0; i < names.length; i++) {
    // if our next name is a number, we want an array not an object
    if (!base[names[i]] && !names[i + 1] && lastName) {
      base[names[i]] = !isNaN(lastName) ? [] : {}
    } else if (!base[names[i]]) {
      base[names[i]] = !isNaN(names[i + 1]) ? [] : {}
    }

    base = base[names[i]]
  }

  // If a value was given, set it to the last name:
  if (lastName) {
    base[lastName] = value
    base = base[lastName]
  }

  // Return the last object in the hierarchy:
  return base
}

const dummy = () => {}

/**
 * Takes an object and a function to generate the new keys naming for the object
 */
const renameObjectKeys = (obj, fn) => {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = fn(key)
    return { [newKey]: obj[key] }
  })

  return Object.assign({}, ...keyValues)
}

const userHasRole = (currentUser, role) => {
  if (!currentUser || !currentUser.userRoles) {
    return false
  }

  return currentUser.userRoles.indexOf(role) > -1
}

export { createNestedObject, dummy, renameObjectKeys, userHasRole }
