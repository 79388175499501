import { LOOP_PROCESS_STEP_TYPE } from '../../../constants'
import LoopProcessStepOptions from './components/stepOptions/LoopProcessStepOptions'
import LoopProcessAutomating from './components/stepAutomating/LoopProcessAutomating'
import BasicUiHelper from './BasicUiHelper'
import StringHelper from '../../../shared-helpers/StringHelper'

class LoopProcessUiHelper extends BasicUiHelper {
  regex() {
    return LOOP_PROCESS_STEP_TYPE
  }

  name() {
    return 'Run Process for List'
  }

  canTryAgain() {
    return false
  }

  stepOptions() {
    return LoopProcessStepOptions
  }

  // we don't want a submit button
  getStepButtons() {
    return []
  }

  getOutputOptions(step) {
    const executionOutputs = step.stepOptions.childOutputs.map((child) =>
      child.executionOutput.map((output) => ({
        id: JSON.stringify({
          step: child.childStep._id,
          key: StringHelper.slugify(output.key),
        }),
        name: `Execution Output -> ${output.display}`,
        textValue: `{${step._id}.resultList.${StringHelper.slugify(output.key)}}`,
        type: 'string',
      })),
    )

    return [
      {
        id: JSON.stringify({ step: step._id, key: '$c_executionUrl' }),
        name: 'Execution URL',
        textValue: `{${step._id}.$c_executionUrl}`,
        type: 'string',
      },
      {
        id: JSON.stringify({ step: step._id, key: '.' }),
        name: 'data',
        textValue: `{${step._id}.resultList}`,
        type: 'collection',
        properties: executionOutputs.flat(),
      },
    ]
  }

  getOutputKeys() {
    return {
      schema: {
        executionList: {
          type: 'ExecutionList',
        },
      },
    }
  }

  stepAutomating() {
    return LoopProcessAutomating
  }
}

export default LoopProcessUiHelper
