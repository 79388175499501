export default [
  {
    title: 'Personal Dashboard',
    path: '/dashboards/personal',
    icon: 'tachometer',
  },
  {
    title: 'Solution Settings',
    path: '/dashboards/solution-settings',
    icon: 'cog',
  },
  {
    title: 'Solutions',
    path: '/solutions',
    icon: 'folder-open',
    singleStepUser: true,
  },
  {
    title: 'The Vault',
    icon: 'lock',
    path: '/vault',
  },
  {
    title: 'Processes',
    icon: 'tasks',
    links: [
      {
        title: 'Active Executions',
        path: '/processes/active',
        icon: 'play',
      },
      {
        title: 'Playbook',
        path: '/processes',
        icon: 'briefcase',
      },
      {
        title: 'New Process',
        path: '/processes/new',
        icon: 'plus',
        manager: true,
      },
      {
        title: 'Bundles',
        path: '/processes/bundles',
        icon: 'folder-open',
      },
      {
        title: 'Schedules',
        path: '/processes/schedules',
        icon: 'clock-o',
      },
      {
        title: 'Background Tasks',
        path: '/processes/background-tasks',
        icon: 'refresh',
      },
    ],
  },
  {
    title: 'Marketplace',
    icon: 'group',
    path: '/marketplace',
  },
  {
    title: 'Admin',
    icon: 'cog',
    manager: true,
    links: [
      {
        title: 'Users',
        icon: 'users',
        path: '/admin/users',
      },
      {
        title: 'Integrations',
        icon: 'download',
        links: [
          {
            title: 'Enabled Integrations',
            path: '/admin/integrations',
            icon: 'list',
          },
          {
            title: 'Available Integrations',
            path: '/admin/integrations/available',
            icon: 'plus',
          },
        ],
      },
      {
        title: 'System Settings',
        path: '/admin/system-settings',
        icon: 'cog',
      },
    ],
  },
  {
    title: 'Docs',
    path: '/docs',
    icon: 'question',
  },
]
