import _ from 'underscore';
import ObjectId from 'bson-objectid';
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import map from 'lodash/map';
import {
  reduxForm,
  Field,
  FieldArray,
  submit,
  getFormValues,
  getFormInitialValues,
} from 'redux-form';
import { connect } from 'react-redux';
import {
  Loading,
  HoverHelp,
  TextInput,
  LoadingButton,
  MarkdownInput,
  SelectInput,
} from 'lib/acromyrmex';
import { LinkContainer } from 'react-router-bootstrap';
import {
  getOperation,
  getSuccessResponseAttributes,
} from '../../../shared-helpers/ApiHelper';
import {
  Button,
  Form,
  Row,
  Col,
  ButtonGroup,
} from '../../../utility/UiComponents';
import StructureAttributeSelectInput from '../../shared/form/StructureAttributeSelectInput';
import ProcessFormStep from './ProcessFormStep';
import ProcessFormStepWrapper from './ProcessFormStepWrapper';
import ProcessGraph from './ProcessGraph';
import StepHelper from '../steps/UiStepHelper';
import PageLayout from '../../shared/Layout/PageLayout';
import { requiredValidator } from '../../../utility/formValidators';
import { createExecution as createExecutionAction } from '../../../actions/executions';
import { loadStructureList as loadStructureListAction } from '../../../actions/vault';
import { loadEnabledIntegrations as loadEnabledIntegrationsAction } from '../../../actions/enabledIntegrations';
import { loadSettings as loadSettingsAction } from '../../../actions/processes';
import {
  getActionFromStepType,
  getIntegrationFromStepType,
} from '../../../utility/integrationHelpers';
import TriggerInitiationOptions from './InitiationOptions/TriggerInitiationOptions';
import StringHelper from '../../../shared-helpers/StringHelper';
import { createNestedObject } from '../../../shared-helpers/ObjectHelper';
import AddButton from '../../shared/form/AddButton';
import { ToggleIcon } from './StepToolbar';
import IntegrationSelect from '../steps/components/stepOptions/integration/IntegrationSelect';

class ProcessForm extends React.Component {
  static renderVaultReplaceAttribute(step, attribute, name, vaultStructure) {
    return (
      <Col
        xs={12}
        lg={6}
        style={{ marginBottom: 10 }}
        key={name}
        className="merge-attribute"
      >
        {!vaultStructure && <Loading />}
        {vaultStructure && (
          <Field
            name={`${step}.stepOptions.attributes.${name}`}
            label={name.replace('$c_root', '(root)')}
            component={StructureAttributeSelectInput}
            slug={vaultStructure}
            type="text"
            enableEmpty
          />
        )}
      </Col>
    );
  }

  static calculateStepOutputOptions(formValues, integrations, structureList) {
    const newVals = [];

    formValues.steps.forEach((step) => {
      const outputOptions = StepHelper.getStepOutputOptions(
        step,
        integrations,
        structureList,
        formValues,
      );

      newVals.push(outputOptions);
    });

    return newVals;
  }

  constructor() {
    super();

    this.state = {
      displayGraph: false,
      // start with the first step in the list active
      activeStep: null,
      stepTypes: [],
      availableStepOutputOptions: [],
      outputOptionsLoaded: false,
      initiationOptionsLoaded: false,
      showInitationOptions: false,
      // stepOptionsLoaded: false,
      initiationOutputOptions: [],
      executionOutputOptions: [
        {
          id: JSON.stringify({ step: 'execution', key: '$c_executionUrl' }),
          name: 'Execution URL',
          stepName: 'Execution',
          textValue: '{execution.$c_executionUrl}',
          type: 'string',
        },
        {
          id: JSON.stringify({ step: 'execution', key: '$c_currentTime' }),
          name: 'Current Date & Time',
          stepName: 'Execution',
          textValue: '{execution.$c_currentTime}',
          type: 'string',
        },
        {
          id: JSON.stringify({ step: 'execution', key: '$c_currentDay' }),
          name: 'Current Day',
          stepName: 'Execution',
          textValue: '{execution.$c_currentDay}',
          type: 'string',
        },
        {
          id: JSON.stringify({ step: 'execution', key: '$c_currentEpoch' }),
          name: 'Current Timestamp',
          stepName: 'Execution',
          textValue: '{execution.$c_currentEpoch}',
          type: 'string',
        },
        {
          id: JSON.stringify({ step: 'execution', key: '$c_currentUser' }),
          name: 'Current User',
          stepName: 'Execution',
          textValue: '{execution.$c_currentUser}',
          type: 'string',
        },
      ],
    };

    this.renderSteps = this.renderSteps.bind(this);

    this.addStep = this.addStep.bind(this);

    this.removeStep = this.removeStep.bind(this);

    this.copyStep = this.copyStep.bind(this);
  }

  componentDidMount() {
    const { loadStructureList, loadEnabledIntegrations, loadSettings } =
      this.props;

    loadStructureList();
    loadEnabledIntegrations();
    loadSettings();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { integrations, formValues, structureList, integrationOptions } =
      nextProps;
    const { availableStepOutputOptions, activeStep } = this.state;
    const stepTypes = StepHelper.getStepTypes();

    if (integrations && integrations.length > 0) {
      // if there are integrations and integration options: set step types
      if (
        !_.isEqual(integrationOptions, {}) &&
        !stepTypes.includes(Object.values(integrationOptions)[0])
      ) {
        const triggerTypes = Object.values(integrationOptions).filter(
          (option) => option.trigger,
        );

        stepTypes.push(...Object.values(integrationOptions));

        this.setState({ stepTypes, triggerTypes });
      }
    } else {
      // if there are no integrations and there are/aren't integration options
      //  -> set contuit step types
      this.setState({ stepTypes });
    }

    // set up our initial available output options

    if (formValues?.steps?.length && activeStep === null) {
      this.setState({ activeStep: formValues.steps[0]._id });
    }
    // set up our initial trigger output options
    if (
      integrations &&
      integrations.length > 0 &&
      !availableStepOutputOptions.length &&
      structureList.length
    ) {
      const cb = () => {
        this.setupOutputOptions(nextProps);
      };
      if (
        ['trigger', 'cbd-event'].includes(formValues.initiation.initiationType)
      ) {
        this.setState(
          {
            initiationOutputOptions: this.getInitiationOptions({}, nextProps),
            initiationOptionsLoaded: true,
          },
          cb,
        );
      } else {
        this.setState(
          {
            initiationOptionsLoaded: true,
          },
          cb,
        );
      }
    }

    // check to see if any steps just got an _id
    if (!formValues.steps) {
      return;
    }

    let shouldUpdate = false;
    formValues.steps.forEach((step, index) => {
      if (
        (formValues.steps &&
          formValues.steps[index] &&
          step._id !== formValues.steps[index]._id) ||
        (step.stepOptions.childOutputs && step.stepOptions.childOutputs?.length)
      ) {
        shouldUpdate = true;
      }
    });

    if (shouldUpdate) {
      const newVals = ProcessForm.calculateStepOutputOptions(
        formValues,
        integrations,
        structureList,
      );
      this.setState({
        availableStepOutputOptions: newVals,
        outputOptionsLoaded: true,
      });
    }
  }

  setupOutputOptions(propsOverride) {
    const {
      integrations = [],
      formValues = { steps: [] },
      structureList = [],
    } = propsOverride || this.props;
    const { availableStepOutputOptions, initiationOptionsLoaded } = this.state;

    const isDataInvalid =
      !formValues?.steps?.length ||
      !integrations.length ||
      !structureList.length ||
      !initiationOptionsLoaded ||
      availableStepOutputOptions.length > 0;

    if (isDataInvalid) {
      this.setState({
        outputOptionsLoaded: true,
      });
      return;
    }

    const newVals = ProcessForm.calculateStepOutputOptions(
      formValues,
      integrations,
      structureList,
    );
    this.setState({
      availableStepOutputOptions: [...availableStepOutputOptions, ...newVals],
      outputOptionsLoaded: true,
    });
  }

  getInitiationOptions(
    { triggerTypeOverride = null, initiationOptionsOverride } = {},
    propsOverride = null,
  ) {
    const { formValues, integrations, structureList } =
      propsOverride || this.props;
    const initiationType = get(formValues, 'initiation.initiationType');
    const triggerType =
      triggerTypeOverride ||
      get(formValues, 'initiation.initiationOptions.triggerType');

    if (initiationType === 'cbd-event') {
      const attributes = {
        'rule.org-id': 'Rule -> Organization ID',
        'rule.network-id': 'Rule -> Network ID',
        'rule.node-id': 'Rule -> Node ID',
        'rule.event-type': 'Rule -> Event Type',
        'rule.action': 'Rule -> Action',
        'rule.approval': 'Rule -> Approval',
        'rule.collaboration': 'Rule -> Collaboration',
        'event.event-id': 'Event -> Event ID',
        'event.org-id': 'Event -> Organization ID',
        'event.network-id': 'Event -> Network ID',
        'event.node-id': 'Event -> Node ID',
        'event.node-type': 'Event -> Node Type',
        'event.sub-type': 'Event -> Sub Type',
        'event.type': 'Event -> Event Type',
        'event.severity': 'Event -> Severity',
        // 'event.correlator': 'Event -> Correlator',
        'event.english-string': 'Event -> English String',
      };

      return map(attributes, (value, key) => ({
        id: JSON.stringify({ step: 'initiation', key }),
        name: value,
        stepName: 'Initiation',
        textValue: `{initiation.${key}}`,
        type: 'string',
      }));
    }

    if (!triggerType) {
      return [];
    }

    let { initiationOptions } = formValues.initiation;
    initiationOptions = initiationOptionsOverride || initiationOptions;

    if (triggerType === 'generic') {
      const objToOptions = (objectInQuestion, prefix = '') => {
        const opts = [];
        _.each(objectInQuestion, (objOrType, key) => {
          if (_.isObject(objOrType)) {
            if (key === 0) {
              const arrayPrefix = prefix.substring(0, prefix.length - 1);
              opts.push({
                id: JSON.stringify({ step: 'initiation', key: arrayPrefix }),
                name: `${arrayPrefix}`,
                stepName: 'Initiation',
                textValue: `{initiation.${arrayPrefix}}`,
                properties: objToOptions(objOrType, prefix),
                type: 'collection',
              });
            } else {
              opts.push(...objToOptions(objOrType, `${prefix}${key}.`));
            }
          } else {
            opts.push({
              id: JSON.stringify({
                step: 'initiation',
                key: `${prefix}${key}`,
              }),
              name: `${prefix}${key}`,
              stepName: 'Initiation',
              textValue: `{initiation.${prefix}${key}}`,
              type: objOrType,
            });
          }
        });

        return opts;
      };

      const obj = {};
      (initiationOptions.incomingData || []).forEach((dataField) => {
        if (dataField.key) {
          createNestedObject(obj, dataField.key.split('.'), dataField.type);
        }
      });

      // recursively create the options
      return objToOptions(obj);
    }

    return StepHelper.getStepOutputOptions(
      {
        stepType: triggerType,
        name: 'Initiation',
        _id: 'initiation',
      },
      integrations,
      structureList,
      formValues,
    );
  }

  getSettingsOptions() {
    const { settings } = this.props;

    return map(settings, (value, key) => ({
      id: JSON.stringify({ step: 'settings', key }),
      name: value.name,
      stepName: 'Settings',
      textValue: `{settings.${key}}`,
      type: 'string',
    }));
  }

  getFormStepById(id) {
    const {
      formValues: { steps },
    } = this.props;

    if (!steps) {
      return null;
    }

    return steps.find((item) => item._id === id);
  }

  getApiResponseAttributes(step, thisStep) {
    const { integrations, structureList } = this.props;
    if (
      !thisStep ||
      !thisStep.stepOptions ||
      !thisStep.stepOptions.sourceData
    ) {
      return { attributes: [], fields: [] };
    }

    const sourceStepId = StringHelper.getStepIdFromTemplate(
      thisStep.stepOptions.sourceData,
    );

    if (!sourceStepId) {
      return { attributes: [], fields: [] };
    }

    // By default, find and use the selected source step
    let selectedSourceStep = this.getFormStepById(sourceStepId.toString());

    // If source step has source data, find and use that step as the source instead
    if (selectedSourceStep && selectedSourceStep.stepOptions.sourceData) {
      const selectedSourceStepId = StringHelper.getStepIdFromTemplate(
        selectedSourceStep.stepOptions.sourceData,
      );
      selectedSourceStep = this.getFormStepById(
        selectedSourceStepId.toString(),
      );
    }

    let responseAttributes = [];

    if (selectedSourceStep) {
      const integrationName = getIntegrationFromStepType(
        selectedSourceStep.stepType,
      );
      const integration = integrations.find(
        (item) => item.spec['x-integration-slug'] === integrationName,
      );
      const action = getActionFromStepType(selectedSourceStep.stepType);

      if (integration) {
        const operation = getOperation(integration.spec, action);
        responseAttributes = getSuccessResponseAttributes(operation);
      }
    }

    if (selectedSourceStep && selectedSourceStep.stepType === 'vault-query') {
      const sourceStructure = structureList.find(
        (item) => item.slug === selectedSourceStep.stepOptions.slug,
      );

      if (!sourceStructure) {
        return { attributes: [], fields: [] };
      }

      // responseAttributes = sourceStructure.attributes; // This is going to be an array
      // Convert array to object
      responseAttributes = sourceStructure.attributes.reduce((acc, cur) => {
        acc[cur.key] = cur;
        return acc;
      }, {});
    }

    // for an object we just use the attributes
    let attrs = responseAttributes;
    if (
      responseAttributes &&
      responseAttributes['.'] &&
      responseAttributes['.'].type === 'collection'
    ) {
      attrs = responseAttributes['.'].properties;
    }

    return {
      attributes: attrs || [],
      fields: _.map(attrs || [], (value, key) =>
        ProcessForm.renderVaultReplaceAttribute(
          step,
          value,
          key,
          thisStep.stepOptions.vaultStructure,
        ),
      ),
    };
  }

  getProcessStatus() {
    const { formValues } = this.props;

    if (formValues.isDraft) {
      return 'Draft';
    }
    if (formValues.isArchived) {
      return 'Archived';
    }
    if (formValues.isActive) {
      return 'Live';
    }
    return 'New';
  }

  getStatusButtons() {
    const { formValues, valid, isCreating, change, dispatch } = this.props;

    return (
      <div style={{ marginTop: '7px' }}>
        <span style={{ marginRight: '5px' }}>
          Status:
          {this.getProcessStatus()}
        </span>
        {(formValues.isActive || formValues.isArchived) && (
          <LoadingButton
            label="Return to Draft"
            variant="primary"
            size="xsmall"
            className="pull-right"
            disabled={!valid}
            loading={isCreating}
            style={{ marginLeft: '10px' }}
            loadingLabel="Returning to Draft"
            onClick={() => {
              change('isDraft', true);
              change('isActive', false);
              change('isArchived', false);
              setTimeout(() => {
                dispatch(submit('processForm'));
              });
            }}
          />
        )}
        {formValues.isActive && (
          <LoadingButton
            label="Archive"
            variant="primary"
            size="xsmall"
            className="pull-right"
            disabled={!valid}
            loading={isCreating}
            style={{ marginLeft: '10px' }}
            loadingLabel="Archiving Process"
            onClick={() => {
              change('isDraft', false);
              change('isActive', false);
              change('isArchived', true);
              setTimeout(() => {
                dispatch(submit('processForm'));
              });
            }}
          />
        )}
        {formValues.isDraft && (
          <LoadingButton
            label="Go Live"
            variant="primary"
            size="xsmall"
            className="pull-right"
            disabled={
              !valid || (formValues.steps && formValues.steps.length === 0)
            }
            loading={isCreating}
            style={{ marginLeft: '10px' }}
            loadingLabel="Going Live"
            onClick={() => {
              change('isDraft', false);
              change('isActive', true);
              change('isArchived', false);
              setTimeout(() => {
                dispatch(submit('processForm'));
              });
            }}
          />
        )}
      </div>
    );
  }

  getPublishButtonLabel() {
    const { formValues } = this.props;

    if (formValues.isArchived) {
      return 'Restore Process';
    }
    if (formValues.isActive) {
      return 'Save Changes';
    }
    return 'Go Live';
  }

  getPublishButtonLoadingLabel() {
    const { formValues } = this.props;

    if (formValues.isArchived) {
      return 'Restoring Process';
    }
    if (formValues.isActive) {
      return 'Saving Changes';
    }
    return 'Publishing Process';
  }

  addStep(fields, index = null, stepObject = {}) {
    const steps = (fields && fields.getAll()) || [];
    const maxStep = steps.length ? _.max(_.pluck(steps, 'step')) : 0;
    const insertIndex = index || fields.length;
    const hexObjId = ObjectId().toHexString();

    // basic has no options
    this.setState((state) => {
      const opts = [...state.availableStepOutputOptions];
      opts.splice(insertIndex, 0, []);
      return {
        availableStepOutputOptions: opts,
      };
    });

    fields.insert(insertIndex, {
      _id: hexObjId,
      step: Number(maxStep) + 1,
      stepType: 'basic',
      stepOptions: { description: '' },
      ...stepObject,
    });
  }

  removeStep(fields, index) {
    // basic has no options
    this.setState((state) => {
      const opts = [...state.availableStepOutputOptions];
      opts.splice(index, 1);
      return {
        availableStepOutputOptions: opts,
      };
    });

    fields.remove(index);
  }

  copyStep(fields, indexToCopy) {
    const { integrations, structureList, formValues } = this.props;
    const steps = fields.getAll();
    const copyStep = steps[indexToCopy];
    const maxStep = steps.length ? _.max(_.pluck(steps, 'step')) : 0;
    const insertIndex = indexToCopy + 1;
    const hexObjId = ObjectId().toHexString();

    // calculate based on step type
    const outputOptions = StepHelper.getStepOutputOptions(
      copyStep,
      integrations,
      structureList,
      formValues,
    );

    this.setState((state) => {
      const opts = [...state.availableStepOutputOptions];
      opts.splice(insertIndex, 0, outputOptions);
      return {
        availableStepOutputOptions: opts,
      };
    });

    fields.insert(insertIndex, {
      _id: hexObjId,
      step: Number(maxStep) + 1,
      name: `Copy of ${copyStep.name}`,
      stepType: copyStep.stepType,
      stepOptions: copyStep.stepOptions,
      conditions: copyStep.conditions,
    });
  }

  renderStep(step, fields, index, onDrop) {
    const {
      displayGraph,
      stepTypes,
      activeStep,
      outputOptionsLoaded,
      availableStepOutputOptions,
      initiationOutputOptions,
      executionOutputOptions,
    } = this.state;
    const {
      integrations,
      structureList,
      array,
      change,
      integrationsHaveLoaded,
      formValues,
      integrationOptions,
    } = this.props;
    const field = fields.get(index);
    // this is only for vault merge
    const { fields: responseFields, attributes } =
      this.getApiResponseAttributes(step, field);

    return (
      <div
        key={step}
        style={{
          display: !displayGraph || field._id === activeStep ? 'block' : 'none',
        }}
      >
        <ProcessFormStep
          key={step}
          onDrop={onDrop}
          index={index}
          displayGraph={displayGraph}
          fields={fields}
          step={step}
          steps={fields.getAll()}
          array={array}
          change={change}
          addStep={this.addStep}
          removeStep={this.removeStep}
          copyStep={this.copyStep}
          responseFields={responseFields}
          attributes={attributes}
          stepTypes={stepTypes}
          integrationsHaveLoaded={integrationsHaveLoaded}
          integrationOptions={integrationOptions}
          initiationOutputOptions={initiationOutputOptions}
          executionOutputOptions={executionOutputOptions}
          availableStepOutputOptions={availableStepOutputOptions}
          settingsOptions={this.getSettingsOptions()}
          structureList={structureList}
          outputOptionsLoaded={outputOptionsLoaded}
          availableStepOutputOptionsString={JSON.stringify(
            availableStepOutputOptions,
          )}
          onChangeAttributeNeeded={(myStep) => {
            // delay it so that field gets updated..
            const delayedFunction = () => {
              myStep = myStep || formValues.steps[index];
              const prevSOO = [...availableStepOutputOptions];
              prevSOO[index] = StepHelper.getStepOutputOptions(
                myStep,
                integrations,
                structureList,
                formValues,
              );

              this.setState({ availableStepOutputOptions: prevSOO });
            };

            if (outputOptionsLoaded) {
              setTimeout(delayedFunction, 1);
            }
          }}
        />
      </div>
    );
  }

  renderSteps({ fields }) {
    const {
      change,
      array,
      formValues,
      integrationsHaveLoaded,
      structureList,
      integrationOptions,
    } = this.props;
    const { triggerTypes, outputOptionsLoaded, showInitationOptions } =
      this.state;
    const onDrop = (dropIndex, dragIndex) => {
      // we should never have a case where we try to replace
      // something larger than the array
      if (dropIndex >= fields.length) {
        return false;
      }

      // store and remove from old location
      this.setState((state) => {
        const dragged = state.availableStepOutputOptions[dragIndex];
        const prevOO = [...state.availableStepOutputOptions];
        prevOO.splice(dragIndex, 1);
        // replace it in the array
        prevOO.splice(dropIndex, 0, dragged);

        return { availableStepOutputOptions: prevOO };
      });

      fields.move(dragIndex, dropIndex);
    };

    const rows = fields.map((member, index) =>
      this.renderStep(member, fields, index, onDrop),
    );

    let initiationOptions = null;
    if (formValues.initiation) {
      switch (formValues.initiation.initiationType) {
        case 'trigger':
          initiationOptions = (
            <TriggerInitiationOptions
              triggerTypes={triggerTypes}
              initiation={formValues.initiation}
              change={change}
              integrationOptions={integrationOptions}
              settingsOptions={this.getSettingsOptions()}
              outputOptionsLoaded={outputOptionsLoaded}
              array={array}
              updateOutputOptions={({
                triggerType,
                initiationOptionsOverride,
              }) => {
                this.setState({
                  initiationOutputOptions: this.getInitiationOptions({
                    triggerTypeOverride: triggerType,
                    initiationOptionsOverride,
                  }),
                });
              }}
            />
          );
          break;
        case 'cbd-event':
          initiationOptions = (
            <div>
              <IntegrationSelect
                name="initiation.initiationOptions.integration"
                stepType="i(find-it-2)[sse-shim]"
                outputOptions={[]}
                outputOptionsLoaded={outputOptionsLoaded}
              />
              <Field
                name="initiation.initiationOptions.vaultStructure"
                label="Rule Structure"
                component={SelectInput}
                options={structureList.map((struct) => ({
                  id: struct.slug,
                  name: struct.display,
                }))}
                help="Which vault structure contains the CBD rules?"
                onChange={this.onVaultStructureChanged}
              />
            </div>
          );
          break;
        case 'basic':
        default:
        // nothing, initiationOptions stays null
      }
    }

    return (
      <div>
        <ProcessFormStepWrapper>
          <div>
            <div
              className="clearfix process-form-step initiation-form-step"
              style={{
                paddingLeft: '5px',
              }}
            >
              <div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    fontSize: '1.2em',
                  }}
                  className="process-form-step-head"
                >
                  <span>Initiation:</span>
                  <div
                    style={{
                      flex: '1',
                      marginTop: '-4px',
                      padding: '0px 15px',
                    }}
                  >
                    <Field
                      name="initiation.initiationType"
                      component={SelectInput}
                      label="Initiation Type"
                      noLabel
                      validate={requiredValidator}
                      options={[
                        { id: 'basic', name: 'Basic' },
                        { id: 'trigger', name: 'Trigger' },
                        { id: 'cbd-event', name: 'CBD Event' },
                      ]}
                      onChange={() => {
                        // To not lose the description when step type changes
                        change('initiation.initiationOptions', {});
                        this.setState({ initiationOutputOptions: [] });
                      }}
                    />
                  </div>
                  <div className="pull-right" style={{ marginTop: '-4px' }}>
                    {initiationOptions && (
                      <Button
                        variant="link"
                        size="xs"
                        onClick={() => {
                          this.setState({
                            showInitationOptions: !showInitationOptions,
                          });
                        }}
                      >
                        <ToggleIcon show={showInitationOptions} icon="fa-cog" />
                      </Button>
                    )}
                  </div>
                </div>
                {showInitationOptions &&
                  integrationsHaveLoaded &&
                  initiationOptions}
              </div>
            </div>
          </div>
          {rows}
        </ProcessFormStepWrapper>
        <Row className="process-form-step">
          <Col xs={12}>
            <AddButton
              size="sm"
              onClick={() => this.addStep(fields)}
              label="Add Step"
            />
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const {
      handleSubmit,
      currentUser,
      onSubmit,
      edit,
      isCreating,
      dirty,
      valid,
      integrationsHaveLoaded,
      formValues,
      change,
      dispatch,
      createExecution,
      routes,
    } = this.props;
    const { displayGraph, activeStep } = this.state;
    // const formDiff = diff(formValues, initialFormValues);

    return (
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{ width: '100%', height: '100%' }}
      >
        <PageLayout
          routes={routes}
          currentUser={currentUser}
          fullWidth
          header={
            <div style={{ display: 'flex' }}>
              <h3 style={{ float: 'left', margin: '3px 0 0 0' }}>
                {edit ? 'Edit' : 'Create'}
              </h3>
              <div style={{ flex: 1, padding: '0px 15px' }}>
                <Field
                  name="name"
                  component={TextInput}
                  noLabel
                  label="Name"
                  validate={requiredValidator}
                  help="How the process will be referenced in the future"
                  type="text"
                />
              </div>
              <div style={{ float: 'right' }}>{this.getStatusButtons()}</div>
            </div>
          }
          content={[
            <div>
              <Col
                xs={12}
                sm={8}
                style={{ float: 'left', padding: '0px 15px' }}
              >
                <Row>
                  <Field
                    name="description"
                    component={MarkdownInput}
                    vertical
                    label="Description"
                    help="General information to give context"
                    type="text"
                  />
                </Row>
              </Col>
              <Col
                xs={12}
                sm={4}
                style={{ float: 'right', padding: '0px 15px' }}
              >
                <Row>
                  <Field
                    name="category"
                    component={TextInput}
                    label="Category"
                    type="text"
                    vertical
                  />
                  <Field
                    name="subCategory"
                    vertical
                    component={TextInput}
                    label="Sub-Category"
                    type="text"
                  />
                </Row>
              </Col>
            </div>,
            <Col xs={12}>
              <fieldset>
                <legend className="clearfix">
                  Steps{' '}
                  <HoverHelp help="Steps are the building blocks of a process. Using different step types, you are able to construct very powerful processes." />
                  <ButtonGroup className="pull-right">
                    <Button
                      variant="default"
                      size="xs"
                      active={!displayGraph}
                      onClick={() => {
                        this.setState({ displayGraph: false });
                      }}
                    >
                      Form
                    </Button>
                    <Button
                      variant="default"
                      size="xs"
                      active={displayGraph}
                      onClick={() => {
                        this.setState({ displayGraph: true });
                      }}
                    >
                      Graph
                    </Button>
                  </ButtonGroup>
                </legend>
                {integrationsHaveLoaded && displayGraph && (
                  <div className="pull-left" style={{ width: '50%' }}>
                    <ProcessGraph
                      width="100%"
                      className="pull-left"
                      activeStep={activeStep}
                      onActiveStepChanged={(id) => {
                        this.setState({ activeStep: id });
                      }}
                      nodes={formValues.steps.map((s, i) => ({
                        id: s._id,
                        name: i + 1,
                        dependsOn: i > 0 ? [formValues.steps[i - 1]._id] : [],
                      }))}
                    />
                  </div>
                )}
                {integrationsHaveLoaded && (
                  <div
                    className="pull-left"
                    style={{ width: displayGraph ? '50%' : '100%' }}
                  >
                    <FieldArray
                      name="steps"
                      component={this.renderSteps}
                      props={{ displayGraph }}
                    />
                  </div>
                )}
                {!integrationsHaveLoaded && (
                  <span>
                    <Loading />
                    {' Integrations loading..'}
                  </span>
                )}
              </fieldset>
            </Col>,
          ]}
          footer={
            <div>
              {formValues.isDraft && (
                <LoadingButton
                  label="Save"
                  variant="primary"
                  disabled={!valid}
                  loading={isCreating}
                  style={{ marginRight: '10px' }}
                  loadingLabel="Saving Draft"
                  onClick={() => {
                    change('isDraft', true);
                    change('isActive', false);
                    change('isArchived', false);
                    setTimeout(() => {
                      dispatch(submit('processForm'));
                    });
                  }}
                />
              )}
              {formValues.isActive && (
                <LoadingButton
                  label="Save"
                  variant="primary"
                  disabled={
                    !valid ||
                    (formValues.steps && formValues.steps.length === 0)
                  }
                  loading={isCreating}
                  style={{ marginRight: '10px' }}
                  loadingLabel="Saving"
                  onClick={() => {
                    change('isDraft', false);
                    change('isActive', true);
                    change('isArchived', false);
                    setTimeout(() => {
                      dispatch(submit('processForm'));
                    });
                  }}
                />
              )}
              {this.getProcessStatus() === 'New' && (
                <LoadingButton
                  label="Save as Draft"
                  variant="primary"
                  disabled={!valid}
                  loading={isCreating}
                  style={{ marginRight: '10px' }}
                  loadingLabel="Saving Draft"
                  onClick={() => {
                    change('isDraft', true);
                    change('isActive', false);
                    change('isArchived', false);
                    setTimeout(() => {
                      dispatch(submit('processForm'));
                    });
                  }}
                />
              )}
              {this.getProcessStatus() === 'New' && (
                <LoadingButton
                  label="Go Live"
                  variant="primary"
                  disabled={
                    !valid ||
                    (formValues.steps && formValues.steps.length === 0)
                  }
                  loading={isCreating}
                  style={{ marginRight: '10px' }}
                  loadingLabel="Going Live"
                  onClick={() => {
                    change('isDraft', false);
                    change('isActive', true);
                    change('isArchived', false);
                    setTimeout(() => {
                      dispatch(submit('processForm'));
                    });
                  }}
                />
              )}
              {/* Discard Changes Button */}
              {!formValues.isArchived && (
                <LinkContainer
                  to={{ pathname: '/processes' }}
                  style={{ marginRight: '10px' }}
                >
                  <Button variant="default">
                    {edit ? 'Discard Changes' : 'Discard Process'}
                  </Button>
                </LinkContainer>
              )}
              {formValues.isActive &&
                formValues.initiation.initiationType !== 'trigger' && (
                  <LoadingButton
                    variant="success"
                    onClick={() => {
                      createExecution(formValues._id);
                    }}
                    loading={isCreating}
                    loadingLabel="loading.."
                    label={
                      <span>
                        <i className="fa fa-play" />
                        {' Execute'}
                      </span>
                    }
                  />
                )}
              {!valid && (
                <span className="text-danger" style={{ marginRight: '5px' }}>
                  Check form validity!
                </span>
              )}
              {dirty && <span className="text-warning"> Unsaved changes!</span>}
            </div>
          }
        />
      </Form>
    );
  }
}

ProcessForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loadEnabledIntegrations: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  array: PropTypes.shape().isRequired,
  onSubmit: PropTypes.func,
  isCreating: PropTypes.bool.isRequired,
  // initialFormValues: PropTypes.shape(),
  integrationOptions: PropTypes.shape().isRequired,
  formValues: PropTypes.shape(),
  edit: PropTypes.bool,
  loadStructureList: PropTypes.func.isRequired,
  loadSettings: PropTypes.func.isRequired,
  structureList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  integrations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  settings: PropTypes.shape().isRequired,
  dirty: PropTypes.bool,
  valid: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  integrationsHaveLoaded: PropTypes.bool,
  createExecution: PropTypes.func.isRequired,
  currentUser: PropTypes.shape().isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

ProcessForm.defaultProps = {
  // processId: null,
  dirty: false,
  valid: false,
  edit: false,
  integrationsHaveLoaded: false,
  onSubmit: () => {},
  formValues: { steps: [] },
  integrations: [],
  structureList: [],
  // initialFormValues: {}
};

const mapStateToProps = (state) => ({
  vault: state.vault,
  integrationOptions: state.enabledIntegrations.integrationOptions,
  integrations: state.enabledIntegrations.enabledIntegrations.rows,
  integrationsHaveLoaded:
    state.enabledIntegrations.enabledIntegrations.hasLoaded,
  structureList: state.vault.structureList.rows,
  settings: state.processes.settings.value,
  settingsLoading: state.processes.settings.isLoading,
  isCreating:
    state.processes.process.isCreating || state.processes.process.isUpdating,
  formValues: getFormValues('processForm')(state),
  initialFormValues: getFormInitialValues('processForm')(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadStructureList: loadStructureListAction,
      loadEnabledIntegrations: loadEnabledIntegrationsAction,
      loadSettings: loadSettingsAction,
      createExecution: createExecutionAction,
    },
    dispatch,
  );

const form = reduxForm({
  form: 'processForm',
  enableReinitialize: true,
})(ProcessForm);
export default connect(mapStateToProps, mapDispatchToProps)(form);
