"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _superagent = _interopRequireDefault(require("superagent"));
class AuthenticationServer {
  static getUsers(serverUri, _ref) {
    var {
      token,
      ids = ''
    } = _ref;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('AuthenticationServer.getUsers requires a token');
    }
    return request.get("".concat(serverUri, "/users")).query({
      id: ids
    }).set('x-access-token', token).then(res => res.body).catch(err => {
      throw new Error("Error fetching users: ".concat(err));
    });
  }
  static getUser(serverUri, _ref2) {
    var {
      token,
      id = ''
    } = _ref2;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('AuthenticationServer.getUser requires a token');
    }
    if (!id) {
      throw new Error('AuthenticationServer.getUser requires an Id');
    }
    return request.get("".concat(serverUri, "/users/").concat(id)).set('x-access-token', token).then(res => res.body).catch(err => {
      throw new Error("Error fetching users: ".concat(err));
    });
  }
  static getSimpleUsers(serverUri, _ref3) {
    var {
      token,
      ids = []
    } = _ref3;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('AuthenticationServer.getSimpleUsers requires a token');
    }
    return request.get("".concat(serverUri, "/users/simple")).query({
      id: ids
    }).set('x-access-token', token).then(res => res.body).catch(err => {
      throw new Error("Error fetching users: ".concat(err));
    });
  }
  static doesUserExist(serverUri, _ref4) {
    var {
      email
    } = _ref4;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!email) {
      throw new Error('AuthenticationServer.getUsers requires a email');
    }
    return request.get("".concat(serverUri, "/user/exists")).query({
      email
    }).then(res => res.body).catch(err => {
      throw new Error("Error fetching users: ".concat(err));
    });
  }
  static checkAuthentication(serverUri, req, res, next) {
    var token = req.body.token || req.query.token || req.headers['x-access-token'];
    return _superagent.default.post("".concat(serverUri, "/token/").concat(token)).query(req.query).then(response => {
      var {
        user,
        internalCall
      } = response.body;

      // store the returned user for later access
      req.currentUser = user;
      req.token = token;
      req.internalCall = internalCall;
      return next();
    }).catch(e => {
      console.log(e);
      res.status(401).send({
        success: false,
        message: 'Failed to authenticate token.'
      });
    });
  }
  static checkPasswordCode(serverUri, _ref5) {
    var {
      email,
      newPassword,
      code
    } = _ref5;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!email) {
      throw new Error('AuthenticationServer.checkPasswordCode requires a email');
    }
    if (!newPassword) {
      throw new Error('AuthenticationServer.checkPasswordCode requires a newPassword');
    }
    if (!code) {
      throw new Error('AuthenticationServer.checkPasswordCode requires a code');
    }
    return request.post("".concat(serverUri, "/checkPasswordCode")).send({
      email,
      newPassword,
      code
    }).then(res => res.body).catch(err => {
      if (err && err.response && err.response.body && err.response.body.message) {
        err = err.response.body.message;
      }
      throw new Error("Error checking password code: ".concat(err));
    });
  }
  static updateUser(serverUri, _ref6) {
    var {
      token,
      user
    } = _ref6;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!user) {
      throw new Error('AuthenticationServer.updateUser requires an user');
    }
    if (!token) {
      throw new Error('AuthenticationServer.updateUser requires a token');
    }
    return request.put("".concat(serverUri, "/users/").concat(user._id)).send(user).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error updating user, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static disableUser(serverUri, _ref7) {
    var {
      token,
      userId
    } = _ref7;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!userId) {
      throw new Error('AuthenticationServer.disableUser requires an userId');
    }
    if (!token) {
      throw new Error('AuthenticationServer.disableUser requires a token');
    }
    return request.post("".concat(serverUri, "/users/").concat(userId, "/disable")).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error updating user, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static enableUser(serverUri, _ref8) {
    var {
      token,
      userId
    } = _ref8;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!userId) {
      throw new Error('AuthenticationServer.disableUser requires an userId');
    }
    if (!token) {
      throw new Error('AuthenticationServer.disableUser requires a token');
    }
    return request.post("".concat(serverUri, "/users/").concat(userId, "/enable")).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error updating user, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static addApiKey(serverUri, _ref9) {
    var {
      token,
      user
    } = _ref9;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!user) {
      throw new Error('AuthenticationServer.addApiKey requires an user');
    }
    if (!token) {
      throw new Error('AuthenticationServer.addApiKey requires a token');
    }
    return request.post("".concat(serverUri, "/users/").concat(user._id, "/apiKeys")).send(user).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error creating api key, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static deleteApiKey(serverUri, _ref10) {
    var {
      token,
      user,
      apiKeyId
    } = _ref10;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!user) {
      throw new Error('AuthenticationServer.deleteApiKey requires an user');
    }
    if (!token) {
      throw new Error('AuthenticationServer.deleteApiKey requires a token');
    }
    if (!apiKeyId) {
      throw new Error('AuthenticationServer.deleteApiKey requires a apiKeyId');
    }
    return request.delete("".concat(serverUri, "/users/").concat(user._id, "/apiKeys/").concat(apiKeyId)).send(user).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error deleting api key, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static createUser(serverUri, _ref11) {
    var {
      token,
      user
    } = _ref11;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!user) {
      throw new Error('AuthenticationServer.createUser requires an user');
    }
    if (!token) {
      throw new Error('AuthenticationServer.createUser requires a token');
    }
    return request.post("".concat(serverUri, "/newUser")).send(user).set('x-access-token', token).then(res => res.body).catch(err => {
      var {
        status,
        response: {
          body
        }
      } = err;
      var msg = status === 500 ? 'Unknown error creating user, please try again later.' : body.message;
      throw new Error(msg);
    });
  }
  static getAvailableUserRoles(serverUri, _ref12) {
    var {
      token
    } = _ref12;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!token) {
      throw new Error('AuthenticationServer.getAvailableUserRoles requires a token');
    }
    return request.get("".concat(serverUri, "/users/userRoles")).query({}).set('x-access-token', token).then(res => res.body).catch(err => {
      throw new Error("Error fetching available user roles: ".concat(err));
    });
  }
  static resetPassword(serverUri, _ref13) {
    var {
      email
    } = _ref13;
    var request = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _superagent.default;
    if (!email) {
      throw new Error('AuthenticationServer.resetPassword requires a email');
    }
    return request.post("".concat(serverUri, "/resetPassword")).send({
      email
    }).then(res => res.body).catch(err => {
      var {
        response: {
          body
        }
      } = err;
      throw new Error(body && body.message || 'Unknown error resetting password, please try again later.');
    });
  }
  constructor(serverUri) {
    var request = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _superagent.default;
    this._serverUri = serverUri;
    this._request = request;
  }
  authenticated(req, res, next) {
    return AuthenticationServer.checkAuthentication(this._serverUri, req, res, next);
  }
  checkPasswordCode(options) {
    return AuthenticationServer.checkPasswordCode(this._serverUri, options, this._request);
  }
  getUsers(options) {
    return AuthenticationServer.getUsers(this._serverUri, options, this._request);
  }
  getUser(options) {
    return AuthenticationServer.getUser(this._serverUri, options, this._request);
  }
  getSimpleUsers(options) {
    return AuthenticationServer.getSimpleUsers(this._serverUri, options, this._request);
  }
  updateUser(options) {
    return AuthenticationServer.updateUser(this._serverUri, options, this._request);
  }
  disableUser(options) {
    return AuthenticationServer.disableUser(this._serverUri, options, this._request);
  }
  enableUser(options) {
    return AuthenticationServer.enableUser(this._serverUri, options, this._request);
  }
  addApiKey(options) {
    return AuthenticationServer.addApiKey(this._serverUri, options, this._request);
  }
  deleteApiKey(options) {
    return AuthenticationServer.deleteApiKey(this._serverUri, options, this._request);
  }
  createUser(options) {
    return AuthenticationServer.createUser(this._serverUri, options, this._request);
  }
  doesUserExist(options) {
    return AuthenticationServer.doesUserExist(this._serverUri, options, this._request);
  }
  resetPassword(options) {
    return AuthenticationServer.resetPassword(this._serverUri, options, this._request);
  }
  getAvailableUserRoles(options) {
    return AuthenticationServer.getAvailableUserRoles(this._serverUri, options, this._request);
  }
}
var _default = exports.default = AuthenticationServer;