import each from 'lodash/each'
import get from 'lodash/get'
import { historyPush } from '../history'
import { toast } from 'react-toastify'
import { IntegrationServer } from '@contuit-otf/sdk'
import request from '../utility/request'

const integrationServer = new IntegrationServer('/api/integrations', request)

export function setModalVisible(show = true) {
  return {
    type: 'SET_MODAL_VISIBLE',
    show,
  }
}

export function addEnabledIntegrations(data) {
  return {
    type: 'ADD_ENABLED_INTEGRATIONS',
    data,
  }
}

export function calculateIntegrationOptions(enabledIntegrations) {
  const integrationOptions = {}

  each(enabledIntegrations, (integration) => {
    const slug = integration.spec['x-integration-slug']
    each(integration.spec.paths, (pathObj) => {
      each(pathObj, (actionObj, action) => {
        // ignore the parameters object
        if (action === 'parameters' || actionObj['x-ignore']) {
          return
        }

        const id = `i(${slug})[${actionObj.operationId}]`

        integrationOptions[id] = {
          id,
          name: actionObj['x-action-name'] || actionObj.summary,
          action: actionObj,
          brand: get(integration, 'spec.info.title', 'Integration Error'),
        }
      })
    })

    each(integration.spec['x-triggers'], (triggerObj) => {
      const id = `t(${slug})[${triggerObj.operationId}]`

      integrationOptions[id] = {
        id,
        name: `✨ ${triggerObj['x-trigger-name']}`,
        trigger: triggerObj,
        brand: get(integration, 'spec.info.title', 'Integration Error'),
      }
    })
  })

  return {
    type: 'ADD_INTEGRATION_OPTIONS',
    data: integrationOptions,
  }
}

export function addSimpleEnabledIntegrations(data) {
  return {
    type: 'ADD_SIMPLE_ENABLED_INTEGRATIONS',
    data,
  }
}

export function addEnabledIntegration(data) {
  return {
    type: 'ADD_ENABLED_INTEGRATION',
    data,
  }
}

export function removeEnabledIntegration(id) {
  return {
    type: 'REMOVE_ENABLED_INTEGRATION',
    id,
  }
}

export function setEnabledIntegrationLoading(loading = true) {
  return {
    type: 'ENABLED_INTEGRATION_LOADING',
    loading,
  }
}

export function setEnabledIntegrationsLoading(loading = true) {
  return {
    type: 'ENABLED_INTEGRATIONS_LOADING',
    loading,
  }
}

export function setSimpleEnabledIntegrationsLoading(loading = true) {
  return {
    type: 'SIMPLE_ENABLED_INTEGRATIONS_LOADING',
    loading,
  }
}

export function setEnabledIntegrationDeleting(id, deleting = true) {
  return {
    type: 'ENABLED_INTEGRATION_DELETING',
    deleting,
    id,
  }
}

export function setEnabledIntegrationCreating(creating = true) {
  return {
    type: 'ENABLED_INTEGRATION_CREATING',
    creating,
  }
}

export function loadEnabledIntegrations() {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser
    dispatch(setEnabledIntegrationsLoading())
    integrationServer
      .getEnabledIntegrations({ token })
      .then((enabledIntegrations) => {
        dispatch(addEnabledIntegrations(enabledIntegrations))
        dispatch(calculateIntegrationOptions(enabledIntegrations))
      })
      .catch((err) => {
        toast.error(`Error loading enabledIntegrations1: ${err}`)
      })
      .then(() => {
        dispatch(setEnabledIntegrationsLoading(false))
      })
  }
}

export function loadSimpleEnabledIntegrations() {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser
    dispatch(setSimpleEnabledIntegrationsLoading())
    integrationServer
      .getSimpleEnabledIntegrations({ token })
      .then((enabledIntegrations) => {
        dispatch(addSimpleEnabledIntegrations(enabledIntegrations))
      })
      .catch((err) => {
        toast.error(`Error loading enabledIntegrations2: ${err}`)
      })
      .then(() => {
        dispatch(setSimpleEnabledIntegrationsLoading(false))
      })
  }
}

export function loadEnabledIntegration(id) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser
    dispatch(setEnabledIntegrationLoading())
    integrationServer
      .getEnabledIntegration({ token, enabledIntegrationId: id })
      .then((enabledIntegrations) => {
        dispatch(addEnabledIntegration(enabledIntegrations))
      })
      .catch((err) => {
        toast.error(`Error loading enabledIntegration3: ${err}`)
      })
      .then(() => {
        dispatch(setEnabledIntegrationLoading(false))
      })
  }
}

export function deleteEnabledIntegration(id) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser
    dispatch(setEnabledIntegrationDeleting(id))
    integrationServer
      .deleteEnabledIntegration({ enabledIntegrationId: id, token })
      .then(() => {
        dispatch(removeEnabledIntegration(id))
        toast.success('EnabledIntegration removed successfully')
      })
      .catch((err) => {
        dispatch(setEnabledIntegrationDeleting(id, false))
        toast.error(`Error deleting enabledIntegration: ${err}`)
      })
      .then(() => {
        dispatch(setEnabledIntegrationDeleting(id, false))
      })
  }
}

export function createEnabledIntegration(enabledIntegration) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser
    dispatch(setEnabledIntegrationCreating())
    integrationServer
      .createEnabledIntegration({ enabledIntegration, token })
      .then((data) => {
        toast.success('EnabledIntegration created successfully')

        // go back to detail page
        const url = `/admin/integrations/${data._id}`
        historyPush(url)
      })
      .catch((err) => {
        toast.error(`Error creating enabledIntegration: ${err}`)
      })
      .then(() => {
        dispatch(setEnabledIntegrationCreating(false))
      })
  }
}

export function updateEnabledIntegration(
  enabledIntegrationId,
  enabledIntegration,
) {
  return (dispatch, getState) => {
    const { token } = getState().users.currentUser
    dispatch(setEnabledIntegrationLoading())
    integrationServer
      .updateEnabledIntegration({
        enabledIntegrationId,
        enabledIntegration: { ...enabledIntegration, spec: undefined },
        token,
      })
      .then(() => {
        dispatch(loadEnabledIntegration(enabledIntegrationId))
        toast.success('EnabledIntegration updated successfully')
        dispatch(setModalVisible(false))
      })
      .catch((err) => {
        toast.error(`Error updating enabledIntegration: ${err}`)
      })
      .then(() => {
        dispatch(setEnabledIntegrationLoading(false))
      })
  }
}

export function createDataConfiguration(integration, newDataConfiguration) {
  return (dispatch) => {
    const split = newDataConfiguration.method.split(':')
    const updateIntegration = {
      ...integration,
      dataConfigurations: [
        ...integration.dataConfigurations,
        {
          ...newDataConfiguration,
          method: split[0],
          path: split[1],
        },
      ],
    }

    if (updateIntegration._id) {
      delete updateIntegration._id
    }

    dispatch(updateEnabledIntegration(integration._id, updateIntegration))
  }
}
