import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DataTable from '../shared/DataTable/DataTable';
import UserButtons from './UserButtons';
import ManageRolesModal from './ManageRolesModal';
import {
  loadUsers as loadUsersAction,
  sendUserInvite as sendUserInviteAction,
} from '../../actions/users';
import PageLayout from '../shared/Layout/PageLayout';
import InviteUserForm from './InviteUserForm';

class ListUsers extends React.Component {
  static getColumns(loadUsers) {
    return [
      {
        display: 'Name',
        key: 'name',
      },
      {
        display: 'Email',
        key: 'email',
      },
      {
        display: 'User Roles',
        key: 'userRoles',
        type: 'array',
      },
      {
        display: 'Status',
        key: 'status',
        type: 'withFormatter',
        sortable: false,
        csvFormatter: (item) =>
          get(item, 'original.disabled') ? 'Inactive' : 'Active',
        formatter: (item) =>
          get(item, 'original.disabled') ? 'Inactive' : 'Active',
      },
      {
        id: 'actions',
        display: 'Actions',
        key: 'actions',
        sortable: false,
        width: 300,
        formatter: (item) => (
          <UserButtons
            key={get(item, 'original._id')}
            item={get(item, 'original')}
            loadUsers={loadUsers}
          />
        ),
      },
    ];
  }

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { loadUsers } = this.props;

    loadUsers();
  }

  getRows() {
    const {
      users: { rows },
    } = this.props;

    return rows;
  }

  render() {
    const { isLoading, currentUser, routes, sendUserInvite, loadUsers } = this.props;

    return (
      <PageLayout
        currentUser={currentUser}
        routes={routes}
        loading={isLoading}
        loadingText="Loading Users"
        header="All Users"
        headerButtons={
          <div style={{ marginTop: '-10px' }}>
            <InviteUserForm onSubmit={sendUserInvite} />
          </div>
        }
        noContentPadding
        content={
          <div>
            <DataTable
              title={false}
              csvTitle="users"
              fetchCSVData={() => ({
                items: this.getRows(),
              })}
              data={this.getRows()}
              columns={ListUsers.getColumns(loadUsers)}
            />
            <ManageRolesModal key="manageUserModal" />
          </div>
        }
      />
    );
  }
}

ListUsers.propTypes = {
  users: PropTypes.shape().isRequired,
  loadUsers: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  sendUserInvite: PropTypes.func.isRequired,
  currentUser: PropTypes.shape().isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

ListUsers.defaultProps = {
  isLoading: false,
};

const mapStateToProps = (state) => ({
  ui: state.ui,
  users: state.users.users,
  currentUser: state.users.currentUser,
  isLoading: state.users.users.isLoading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      loadUsers: loadUsersAction,
      sendUserInvite: sendUserInviteAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ListUsers);
