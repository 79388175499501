import { TemplateInput } from 'lib/acromyrmex';
import BasicUiHelper from './BasicUiHelper';
import { BEGIN_LOCK_BLOCK_STEP_TYPE } from '../../../constants'
import BlockBeginStepSkipped from './components/stepSkipped/BlockBeginStepSkipped';
import BlockBeginStepAutomating from './components/stepAutomating/BlockBeginStepAutomating';

class BeginLockBlockUiHelper extends BasicUiHelper {
  regex() {
    return BEGIN_LOCK_BLOCK_STEP_TYPE;
  }
  name() {
    return 'Begin Lock Block';
  }
  stepOptions() {
    return {
      title: 'Begin Lock Block Step Options',
      fields: ({ outputOptions }) => [
        {
          name: 'lockKey',
          component: TemplateInput,
          label: 'Lock Key',
          options: outputOptions
        }
      ]
    };
  }
  canTryAgain() {
    return true;
  }
  stepSkipped() {
    return BlockBeginStepSkipped;
  }
  stepAutomating() {
    return BlockBeginStepAutomating;
  }
}
export default BeginLockBlockUiHelper;