import _ from 'underscore';
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { TextInput, SelectInput, LoadingButton } from 'lib/acromyrmex';
import { Form, Row, Col } from '../../utility/UiComponents';
import { requiredValidator } from '../../utility/formValidators';
import { loadStructureList as loadStructureListAction } from '../../actions/vault';

// eslint-disable-next-line
class DataConfigurationForm extends React.Component {
  componentWillMount() {
    const { loadStructureList } = this.props;

    loadStructureList();
  }

  render() {
    const { handleSubmit, onSubmit, edit, isCreating, integration, structureList } = this.props;

    const structOptions = _.map(structureList, struct => ({
      id: struct.slug,
      name: struct.display
    }));

    const methodOptions = [];

    _.each(integration.spec.paths, (pathObj, pathString) => {
      _.each(pathObj, (methodObj, methodString) => {
        const header = `${methodObj['x-action-name'] || methodObj.summary} - ${
          methodObj.description
        }`;
        methodOptions.push({
          id: `${methodString}:${pathString}`,
          name: header
        });
      });
    });

    return (
      <div className="content-row content-row-padded process-form box-rest">
        <div className="container">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col xs={12}>
                <Field
                  name="method"
                  component={SelectInput}
                  label={`${integration.spec.info.title} method`}
                  validate={requiredValidator}
                  options={methodOptions}
                />
                <Field
                  name="frequency"
                  component={SelectInput}
                  label="Frequency"
                  options={[
                    {
                      id: 'w',
                      name: 'weekly'
                    },
                    {
                      id: 'd',
                      name: 'daily'
                    },
                    {
                      id: 'h',
                      name: 'hourly'
                    }
                  ]}
                />
                <Field
                  name="startDate"
                  component={TextInput}
                  label="Start Date/Time"
                  type="datetime"
                />
                <Field
                  name="vaultSlug"
                  label="Structure"
                  component={SelectInput}
                  options={structOptions}
                  help="Which vault structure should this be synced with?"
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={12} style={{ marginTop: 10 }}>
                <LoadingButton
                  variant="primary"
                  label={edit ? 'Save Changes' : 'Create Data Configuration'}
                  loading={isCreating}
                  loadingLabel={edit ? 'Saving changes' : 'Creating Data Configuration'}
                  type="submit"
                />
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

DataConfigurationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loadStructureList: PropTypes.func.isRequired,
  integration: PropTypes.shape().isRequired,
  onSubmit: PropTypes.func,
  isCreating: PropTypes.bool.isRequired,
  // formValues: PropTypes.shape(),
  edit: PropTypes.bool,
  structureList: PropTypes.arrayOf(PropTypes.shape()).isRequired
};

DataConfigurationForm.defaultProps = {
  edit: false,
  onSubmit: () => {}
};

const mapStateToProps = state => ({
  structureList: state.vault.structureList.rows,
  integration: state.enabledIntegrations.enabledIntegration.value,
  isCreating:
    state.enabledIntegrations.enabledIntegrations.isCreating ||
    state.enabledIntegrations.enabledIntegrations.isUpdating,
  formValues: state.form.dataConfigurationForm ? state.form.dataConfigurationForm.values : {}
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadStructureList: loadStructureListAction
    },
    dispatch
  );

const form = reduxForm({ form: 'dataConfigurationForm' })(DataConfigurationForm);
export default connect(mapStateToProps, mapDispatchToProps)(form);
